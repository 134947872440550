import {observer} from 'mobx-react-lite';
import {usePageTitle} from '../../../common';
import {IFilehubState} from '../../../earchive/pages/Documents/state/IFilehubState';
import {Header} from '../../../root/components/Header';
import {SharedPageTable} from '../../table/FilehubShareTable';
import {intlFunction} from '../../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import FilehubSharePagination from '../components/FilehubSharePagination';
import {SharesPageSearchBar} from '../../components/SharesPageSearchBar';

interface SharesPageProps{
  state:IFilehubState;
}

const SharesPage = observer(({state} : SharesPageProps) => {

  const {setPageTitle} = usePageTitle();

  setPageTitle(intlFunction(Tr.shares));

  return (
    <div className="p-[28px] flex flex-col gap-2 h-auto overflow-[hidden]">
      <Header>{intlFunction(Tr.shares)}</Header>
      <SharesPageSearchBar disabled={false} setSearchQuery={(q:string) => {alert(q);}}/>
      <SharedPageTable state={state}/>
      <FilehubSharePagination/>
    </div>
  );
});

export default SharesPage;
