import {FC, useEffect, useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {sharedPageTableConfig} from './FilehubShareTableConfig';
import {
  ButtonTertiary,
  ButtonTertiarySize,
  Checkbox,
  CheckboxSize,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  Table,
  TableRow,
  TableRowHeight,
  Tag,
  TagColor,
  TagSize,
  ToastManager,
  ToastVariant,
  useToast,
  useToastState,
} from '@symfonia/brandbook';
import {DateRenderer} from '../../common';
import {getSize, intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Button, LinearProgress} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {intl} from '../../root/IntlProvider';
import {noop, set} from 'lodash';
import {Text} from '@symfonia/symfonia-ksef-components';
import FilehubShareTableMenu from '../shares/components/FilehubShareTableMenu';
import FilehubShareMultipleActionsBar from '../shares/components/FilehubShareMultipleActionsBar';

interface SharedPageTableProps {
  state: IFilehubState;
}

export type SharesType = {
  Id: number;
  InvoiceId: string;
  DocumentOwnNumber: string;
  AttachmentsCount: number;
  Size: number;
  StartDate: string;
  isActive: boolean;
  Link: string | undefined;
  PinsProtection: boolean;
};

const successAlertProps = {
  title: intlFunction(Tr.copiedToClipboard),
  variant: ToastVariant.SUCCESS,
};

export const SharedPageTable: FC<SharedPageTableProps> = observer(({state}) => {
  //const attachments = state.attachments;
  const [selectedShares, setSelectedShares] = useState<SharesType[]>([]);
  const [allSelectedElements, setAllSelectedElements] = useState(false);
  const [shares, setShares] = useState<SharesType[]>([]);
  const [selectedShare, setSelectedShare] = useState<SharesType | undefined>(undefined);
  const [isContextualMenuOpen, setIsContextualMenuOpen] = useState(false);
  const [loadingSharesList, setLoadingSharesList] = useState(true);
  const [actionsButtonRef, setActionsButtonRef] = useState<HTMLButtonElement | null>(null);
  const selectedSharesLength = selectedShares.length;
  const isMultipleActionsBarVisible = selectedSharesLength > 0;
  const areAllRowsSelected = selectedSharesLength === shares.length;

  const addToast = useToast();

  const handleHeaderCheckboxSelect = (checked: boolean) => {
    if (checked) setSelectedShares(shares);
    else setSelectedShares([]);
    setAllSelectedElements(checked);
  };

  const handleCheckboxSelect = (selected: boolean, selectedShare: SharesType) => {
    if (selected) setSelectedShares(prev => [...prev, selectedShare]);
    else setSelectedShares(prev => prev.filter(share => share.Id !== selectedShare.Id));
    setAllSelectedElements(false);
  };

  const getSharesAction = async (): Promise<SharesType[]> => {
    const today = new Date().toISOString().slice(0, 10);

    const tmpSharesList: SharesType[] = [
      {
        Id: 1,
        InvoiceId: '1',
        DocumentOwnNumber: 'fak/01',
        AttachmentsCount: 2,
        Size: 2000,
        StartDate: today,
        isActive: false,
        Link: undefined,
        PinsProtection: false,
      },
      {
        Id: 2,
        InvoiceId: '2',
        DocumentOwnNumber: 'fak/02',
        AttachmentsCount: 3,
        Size: 8000,
        StartDate: today,
        isActive: true,
        Link: 'https://www.figma.com/proto/3WM6Rsniv9Vk9z8rqvdCyC/Makiety-Za%C5%82%C4%85czniki?scaling=min-zoom&content-scaling=fixed&page-id=8909%3A5614&node-id=10582-17420&starting-point-node-id=10582%3A17420&show-proto-sidebar=1',
        PinsProtection: true,
      },
    ];

    return tmpSharesList;
  };

  useEffect(() => {
    async function fetchData() {
      setLoadingSharesList(true);
      setTimeout(async () => {
        const tmp = await getSharesAction();
        setShares(tmp);
      }, 500);
      setLoadingSharesList(false);
    }

    fetchData();
  }, []);

  const sharedPageTableHeaders = sharedPageTableConfig(handleHeaderCheckboxSelect, allSelectedElements);

  const onActionButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, share: SharesType): void => {
    e.stopPropagation();
    setIsContextualMenuOpen(true);
    setActionsButtonRef(e.currentTarget);
    setSelectedShare(share);
  };

  const copyLink = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link);
      addToast(successAlertProps);
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  const onAttachmentsClick = (attachmentsCount: number) => {
    alert(`${intl.formatMessage({id: Tr.attached})}: ${attachmentsCount}`);
  };

  useEffect(() => {
    if (selectedShares.length !== 0 && shares.length === selectedShares.length) {
      setAllSelectedElements(true);
    } else {
      setAllSelectedElements(false);
    }
  }, [selectedShares, shares]);

  return (
    <>
      <ToastManager/>
      <div className="h-[4px] my-[-12px]">{loadingSharesList && <LinearProgress/>}</div>
      <FilehubShareTableMenu
        actionsButtonRef={actionsButtonRef}
        isOpen={isContextualMenuOpen}
        selectedShare={selectedShare}
        setIsOpen={setIsContextualMenuOpen}
        />
      {isMultipleActionsBarVisible && (
        <FilehubShareMultipleActionsBar
        selectedAll={areAllRowsSelected}
        selectedSharesLength={selectedSharesLength}
        selectedShares={selectedShares}
          setSelectedShares={setSelectedShares}
          shares={shares}
        />
      )}
      <Table
        className="overflow-y-auto max-h-[40vh]"
        header={sharedPageTableHeaders}
        tableRowHeight={TableRowHeight.MD}
      >
        {shares.map(share => {
          const {Id, DocumentOwnNumber, AttachmentsCount, Link, Size, StartDate, isActive, PinsProtection} = share;

          return (
            <TableRow>
              <span>
                <Checkbox
                  checked={selectedShares.some(share => share.Id === Id)}
                  size={CheckboxSize.SM}
                  onChange={selected => handleCheckboxSelect(selected, share)}
                  value={`sharedPageTableCheckbox-${Id}`}
                />
              </span>
              <span>{DocumentOwnNumber}</span>
              <span>
                <ButtonTertiary
                  onClick={() => onAttachmentsClick(AttachmentsCount)}
                  size={ButtonTertiarySize.SM}
                  lIcon={IconSvg.ATTACH_FILE}
                  text={`${intl.formatMessage({id: Tr.attached})}: ${AttachmentsCount}`}
                  testId={`${Id}AttachmentsFilehubButton`}
                />
              </span>
              <span>{getSize(Size)}</span>
              <span>{DateRenderer({value: StartDate})}</span>
              <span>
                <Tag
                  className="px-[12px]"
                  color={isActive ? TagColor.GREEN_LIGHT : TagColor.GREY_LIGHT}
                  size={TagSize.MD}
                  text={intlFunction(isActive ? Tr.active : Tr.deactivated)}
                />
              </span>
              <span>
                <div
                  className="inline-flex justify-center items-center gap-[5px] cursor-pointer"
                  onClick={isActive && Link ? () => copyLink(Link) : noop}
                >
                  <Icon
                    svg={IconSvg.FILE_COPY}
                    color={isActive ? IconColor.PRIMARY_500 : IconColor.GREY_400}
                    size={IconSize.MD}
                    className="min-w-[24px]"
                    withHover
                  />
                  <Text className={`text-sm ${!isActive && 'text-gray-300'}`}>
                    {intl.formatMessage({id: Tr.copyLink})}
                  </Text>
                </div>
              </span>
              <span>
                <Tag
                  text={intl.formatMessage({id: PinsProtection ? Tr.yes : Tr.no})}
                  lIcon={PinsProtection ? IconSvg.LOCK : IconSvg.LOCK_OPEN}
                  color={TagColor.GREY_LIGHT}
                  size={TagSize.MD}
                  className="bg-white"
                />
              </span>
              <span>
                <Button
                  onClick={e => {
                    onActionButtonClick(e, share);
                  }}
                  size="small"
                >
                  <MoreVert className="IconWithTransform"/>
                </Button>
              </span>
            </TableRow>
          );
        })}
      </Table>
    </>
  );
});
