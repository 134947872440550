import {ContextualMenu} from '../../../common/components/ContextualMenu/ContextualMenu';
import {IconSvg} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../../root/IntlProvider';
import {SharesType} from '../../table/FilehubShareTable';

type FilehubShareTableMenuProps = {
  actionsButtonRef: HTMLButtonElement | null;
  isOpen: boolean;
  selectedShare?: SharesType;
  setIsOpen: (isOpen: boolean) => void;
};

const FilehubShareTableMenu = ({actionsButtonRef, isOpen, selectedShare, setIsOpen}: FilehubShareTableMenuProps) => {
  const {isActive} = selectedShare || {};

  const actions = [
    {
      icon: IconSvg.FOLDER_OPEN,
      key: Tr.details,
      label: intl.formatMessage({id: Tr.details}),
      onClick: () => {
        console.log('Szczegóły');
      },
    },
    {
      hidden: !isActive,
      icon: IconSvg.LINK_OFF,
      key: Tr.disable,
      label: intl.formatMessage({id: Tr.disable}),
      onClick: () => {
        console.log('Disable');
      },
    },
    {
      hidden: isActive,
      icon: IconSvg.LINK,
      key: Tr.enable,
      label: intl.formatMessage({id: Tr.enable}),
      onClick: () => {
        console.log('Enable');
      },
    },
    {
      icon: IconSvg.SHARE,
      key: Tr.share,
      label: intl.formatMessage({id: Tr.share}),
      onClick: () => {
        console.log('Share');
      },
    },
    {
      icon: IconSvg.DELETE,
      key: Tr.removeButton,
      label: intl.formatMessage({id: Tr.removeButton}),
      onClick: () => {
        console.log('Delete');
      },
    },
  ];

  return (
    <ContextualMenu actions={actions} anchorEl={actionsButtonRef} isOpen={isOpen} onClose={() => setIsOpen(false)}/>
  );
};

export default FilehubShareTableMenu;
