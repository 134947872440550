import {BaseEventConsumer} from '../EventConsumer';
import {SubscriptionStateEnum, WebsocketNotificationType} from '@symfonia-ksef/graphql';
import {Tr} from '../../locales/translationKeys';
import {downloadedInvoicesResultService} from '../../state/rootRepository';
import {EventParams} from '../SubscriptionEventsService';
import {ToastVariant} from '@symfonia/brandbook';
import {intl} from '../../modules/root/IntlProvider';

export class KSeFAutoFetchingInvoicesConsumer extends BaseEventConsumer {
  constructor() {
    super(WebsocketNotificationType.AutoFetchingInvoices, downloadedInvoicesResultService, {
      success: () => {
        const count = downloadedInvoicesResultService.currentResult?.imported;
        const isCancelled = downloadedInvoicesResultService?.notification?.State === SubscriptionStateEnum.Cancelled;
        const manualCancellation = isCancelled ? intl.formatMessage({id: Tr.getInvoicesFromKSeFCancelled}) : '';

        if (count && downloadedInvoicesResultService?.currentResult?.errorItems?.length) {
          return {
            id: Tr.getInvoicesFromKSeFNoPackage,
            color: ToastVariant.WARNING,
            title: intl.formatMessage({id: Tr.getInvoicesFromKSeFNoPackageTitle}),
            values: {manualCancellation: manualCancellation, count},
          };
        }

        return {
          id: downloadedInvoicesResultService.hasError ? Tr.ksefAutoFetchingInvoicesSubError : count ? Tr.ksefAutoFetchingInvoicesSubSuccess : isCancelled ? Tr.getInvoicesFromKSeFNoData : Tr.getInvoicesFromKSeFUpToDate,
          values: {count, manualCancellation},
          color: downloadedInvoicesResultService.hasError ? ToastVariant.ERROR : ToastVariant.SUCCESS,
        };
      },
      error: {id: Tr.ksefAutoFetchingInvoicesSubError},
    });
  }

  public async onUpdate(event: EventParams): Promise<void> {
    await super.onUpdate(event, async () => await downloadedInvoicesResultService.getAsyncData(event, () => downloadedInvoicesResultService.setDownloadType(event.type)));
  }
}
