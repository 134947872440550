import {FC, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from '../../root/components/Text';
import {FormattedMessage} from 'react-intl';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonTertiary, ButtonTertiarySize, IconColor, IconSvg} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import FilehubDesktopMultipleActionsItems from './FilehubDesktopMultipleActionsItems';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {MultipleActionsRequestTypeEnum} from '../../earchive/pages/Documents/state/FilehubState';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {Attachment} from '@symfonia-ksef/graphql';

export type IAction = {
  color: IconColor;
  disabled?: boolean;
  key: Tr;
  icon: IconSvg;
  onClick: () => void;
};

const FilehubMultipleActionsBar: FC<{
  clearSelection: () => void;
  selectedAttachments: Attachment[];
  selectedAttachmentsIds: string[];
  state: IFilehubState;
}> = observer(({clearSelection, selectedAttachments, selectedAttachmentsIds, state}) => {
  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const {downloadAttachmentAction} = useFilehubMethods(state);
  const hasMultipleAttachmentsSelected = selectedAttachmentsIds.length > 1;
  const selectedAttachmentsLength = selectedAttachments.length;

  const changeVisibility = (isPublic: boolean) => {
    state.setMultipleActionsRequestType(
      isPublic ? MultipleActionsRequestTypeEnum.Public : MultipleActionsRequestTypeEnum.Private,
    );

    const changedAttachments = state.attachments.map(attachment => {
      if (selectedAttachmentsIds.includes(attachment.Id)) {
        return {
          ...attachment,
          IsPublic: isPublic,
        };
      }

      return attachment;
    });

    state.setSuccessfulMultipleActionsRequest(null);
    state.setAttachments(changedAttachments);
    state.setAreFooterButtonsShown(true);
  };

  const deleteAttachments = () => {
    state.setMultipleActionsRequestType(MultipleActionsRequestTypeEnum.Delete);
    state.setDeleteAttachmentsIdsList(selectedAttachmentsIds);
    state.setIsDeleteAttachmentModalOpen(true);
  };

  useEffect(() => {
    const deletedAttachments = state.attachments.filter(attachment =>
      state.deleteAttachmentsIdsList.includes(attachment.Id),
    );

    state.setDeleteAttachmentsList(deletedAttachments);
  }, [state.deleteAttachmentsIdsList]);

  const downloadAttachments = async () => {
    if (hasMultipleAttachmentsSelected) {
      state.setMultipleActionsRequestType(MultipleActionsRequestTypeEnum.Download);
      state.setIsDownloadAttachmentModalOpen(true);
    } else {
      await downloadAttachmentAction(selectedAttachmentsIds[0]);
    }
  };

  const publicSelectedAttachmentsLength = selectedAttachments.filter(({IsPublic}) => IsPublic).length;
  const privateSelectedAttachmentsLength = selectedAttachmentsLength - publicSelectedAttachmentsLength;

  const isPublicIconDisabled = selectedAttachmentsLength === publicSelectedAttachmentsLength;
  const isPrivateIconDisabled = selectedAttachmentsLength === privateSelectedAttachmentsLength;

  const actions: IAction[] = [
    {
      color: isPublicIconDisabled ? IconColor.GREY_400 : IconColor.GREEN_600,
      disabled: isPublicIconDisabled,
      key: Tr.publicStatus,
      icon: IconSvg.PUBLIC,
      onClick: () => changeVisibility(true),
    },
    {
      color: isPrivateIconDisabled ? IconColor.GREY_400 : IconColor.GREEN_600,
      disabled: isPrivateIconDisabled,
      key: Tr.privateStatus,
      icon: IconSvg.VPN_LOCK,
      onClick: () => changeVisibility(false),
    },
    {
      color: IconColor.GREEN_600,
      key: Tr.download,
      icon: IconSvg.GET_APP,
      onClick: downloadAttachments,
    },
    {
      color: IconColor.RED_500,
      key: Tr.deleteCategory,
      icon: IconSvg.DELETE_OUTLINE,
      onClick: deleteAttachments,
    },
  ];

  return (
    <div className="w-full flex justify-between items-center bg-grey-50 rounded-[5px] p-[8px_16px]">
      <div>
        <Text small className="font-semibold pr-[8px] border-r-[1px] border-grey-400">
          {selectedAttachments.length}&nbsp;
          <FormattedMessage id={isPhone ? Tr.selected : Tr.elementsSelectedFromTheList}/>
        </Text>
        <ButtonTertiary
          className="text-[0.75em] mx-[8px]"
          onClick={clearSelection}
          size={ButtonTertiarySize.SM}
          text={intl.formatMessage({id: Tr.uncheck})}
        />
      </div>
      {isPhone ? <></> : <FilehubDesktopMultipleActionsItems actions={actions}/>}
    </div>
  );
});

export default FilehubMultipleActionsBar;
