import {FC, PropsWithChildren, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {
  EnvironmentStatus,
  InitializationStatusEnum,
  useOnAutoPostingInitializationCompletedSubscription,
} from '@symfonia-ksef/graphql';
import {GlobalLoader} from '../common';
import {CompanyInactive, CompanyNotFound, CompanyPending} from './components';
import {l} from '../logger/loggerInstance';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {downloadedInvoicesResultService, earchiveState} from '../../state/rootRepository';
import {usePackageStatisticLoader} from './hooks/usePackageStatisticLoader';
import {Tr} from '@symfonia-ksef/locales/keys';
import {useIntl} from 'react-intl';
import {ExpiredTokenModal} from './modals/ExpiredTokenModal';
import {IEarchiveStateMobx} from './IEArchiveStateMobx';
import {ModulesEnum} from '../common/enums/ModulesEnum';
import {UPOMissedInvoicesModal} from './modals';
import {InvoiceDatePickerModal} from './modals/InvoiceDatePickerModal/InvoiceDatePickerModal';
import {isFeatureEnabled} from '../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../common/enums/FeatureSwitchTypeEnum';
import {UserNotAuthorized} from './components/UserNotAuthorized';
import {GettingUPOModal} from './modals/GettingUPOModal';
import {MissingUploadedInvoicesModal} from './modals/NotificationModals/MissingInvoices/MissingUploadedInvoicesModal';
import {GetAutoPostingModuleStatusQueryFunction} from './queries/GetAutoPostingModuleStatusQueryFunction';
import {AutoPostingModuleStatus} from '../auto-posting/AutopostingModuleStatus';
import {AppRoutes} from '../root/Routes';
import {ToastVariant} from '@symfonia/brandbook';
import {DeletedMissedInvoicesModal} from './modals/NotificationModals/MissingInvoices/DeletedMissedInvoicesModal';
import {CheckWhiteListModal} from './modals/NotificationModals/MissingInvoices/CheckWhiteListModal';
import {useModule} from '../root/services/MobXServices/BaseModule';
import {DataTypes} from '../../services/NotificationDataResultManager';
import {PageNotFound} from './components/PageNotFound';

export interface EarchiveModuleInitializationProps extends PropsWithChildren {
  earchiveStateMobx: IEarchiveStateMobx;
}

export const EarchiveModuleInitialization: FC<EarchiveModuleInitializationProps> = observer(
  ({earchiveStateMobx, children}) => {
    const {companyId, tenantId} = useParams();
    const {environments} = earchiveState.environments;
    const {company, tenant} = earchiveState;

    const setIsDemo = earchiveState.layoutState.setIsDemo;
    
    useDeviceViewport(earchiveState.layoutState.setViewport);

    const intl = useIntl();
    const {addAlert} = earchiveState.alertsState;
    useModule(earchiveState);

    useEffect(() => {
      if (companyId) {
        company.setEnvId(companyId).fetch();
      }
      downloadedInvoicesResultService.resetResult(DataTypes.ManualFetching, DataTypes.Archived, DataTypes.AutoFetching);
    }, [companyId]);

    useEffect(() => {
      if (companyId) {
        const isDemo = !!company.currentEnvironment?.Company.IsDemo;
        earchiveStateMobx.setCompanyId(companyId);
        setIsDemo(isDemo);
        if (isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && company.autoPosting) {
          earchiveStateMobx.moduleStatusStore.registerModule(
            ModulesEnum.autoPosting,
            GetAutoPostingModuleStatusQueryFunction(companyId),
          );
          earchiveStateMobx.postingState.setIsPostingConfigured(company.autoPosting.Status ?? false);
        }
      }
    }, [companyId, company.companyId]);

    usePackageStatisticLoader(earchiveState.packageStatistics);

    if (isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && company.autoPosting) {
      earchiveStateMobx.postingState.setIsPostingConfigured(company.autoPosting.Status ?? false);
    }

    useEffect(() => {
      const autoPostingModuleStatus = earchiveStateMobx.moduleStatusStore.getModule(
        ModulesEnum.autoPosting,
      ) as AutoPostingModuleStatus;

      if (company.autoPosting && autoPostingModuleStatus) {
        try {
          if (autoPostingModuleStatus.initializationStatus === InitializationStatusEnum.Failed) {
            addAlert(
              <>
                {intl.formatMessage(
                  {id: Tr.autopostingInitializationFailed},
                  {
                    autopostingUrl: (
                      <Link
                        to={`/earchive/${tenantId}/company/${companyId}/account/posting`}
                        className="font-quicksand underline text-primary-500 hover:text-primary-600 active:text-primary-700 focus-visible:p-[2px] focus-visible:rounded focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-primary-600"
                      >
                        {intl.formatMessage({id: Tr.autopostingRedirectButton})}
                      </Link>
                    ),
                  },
                )}
              </>,
              ToastVariant.ERROR,
              {
                displayDuration: 10000,
                omitIfHasTheSameAlert: true,
              },
            );
          }
        } catch (e) {
          l.error('Error while reading data for autoposting initialization status', e);
        }
      }
    }, [earchiveStateMobx.moduleStatusStore.modules.length, company.companyId, companyId, tenantId]);

    const shouldSkipAutoPostingSubscription = (): boolean => {
      const moduleStatus = earchiveStateMobx.moduleStatusStore.getModule(ModulesEnum.autoPosting);

      // Invalid state
      if (
        company.tenantId === null ||
        company.companyId === null ||
        company.userId === null ||
        company.autoPosting === undefined ||
        company.currentEnvironment === undefined ||
        moduleStatus === undefined
      ) {
        return true;
      }

      // AutoPosting module not subscribed
      if (!moduleStatus.isModulePurchased) {
        return true;
      }

      // AutoPosting module initialization already completed
      return moduleStatus.isModulePurchased && moduleStatus.isModuleInitialized;
    };

    const {data: autoPostingSubscriptionData, loading: isAutoPostingSubscriptionLoading} =
      useOnAutoPostingInitializationCompletedSubscription({
        context: {envId: company.tenantId ?? ''},
        variables: {CompanyId: company.companyId, UserId: company.userId},
        skip: shouldSkipAutoPostingSubscription(),
      });

    useEffect(() => {
      if (autoPostingSubscriptionData) {
        earchiveStateMobx.moduleStatusStore.softResetModules();

        addAlert(
          <>
            {intl.formatMessage(
              {id: Tr.postingInitializationCompleted},
              {
                link: (
                  <Link
                    to={
                      AppRoutes.eArchive.address +
                      '/' +
                      company.tenantId +
                      '/company/' +
                      company.companyId +
                      '/account/posting'
                    }
                    className="font-quicksand underline text-primary-500 hover:text-primary-600 active:text-primary-700 focus-visible:p-[2px] focus-visible:rounded focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-primary-600"
                  >
                    {intl.formatMessage({id: Tr.posting})}
                  </Link>
                ),
              },
            )}
          </>,
          ToastVariant.SUCCESS,
          {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          },
        );
      }
    }, [autoPostingSubscriptionData, isAutoPostingSubscriptionLoading, company.currentEnvironment]);

    if (company.loading) {
      return <GlobalLoader/>;
    }

    if (!company?.currentEnvironment) {
      return <PageNotFound/>;
    }

    if (
      !company.currentEnvironment?.UserProperties?.EArchive ||
      !company.currentEnvironment?.UserProperties?.KSeF
    ) {
      return <UserNotAuthorized/>;
    }

    if (company.error) {
      l.error('Company not found', company.error);
      if (environments) return <UserNotAuthorized/>;
      return <CompanyNotFound/>;
    }

    if (tenant.state.rootTenant?.Status === EnvironmentStatus.Inactive) {
      return <CompanyInactive companyName={tenant.state.rootTenant.Name ?? ''}/>;
    }

    if (tenant.state.rootTenant?.Status === EnvironmentStatus.PendingApproval) {
      return <CompanyPending companyName={tenant.state.rootTenant.Name ?? ''}/>;
    }

    return (
      <>
        <UPOMissedInvoicesModal/>
        <GettingUPOModal/>
        <MissingUploadedInvoicesModal/>
        <DeletedMissedInvoicesModal/>
        <InvoiceDatePickerModal/>
        <CheckWhiteListModal/>
        <ExpiredTokenModal/>
        {children}
      </>
    );
  },
);
