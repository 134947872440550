import {ButtonTertiary, ButtonTertiarySize, IconColor, IconSvg} from '@symfonia/brandbook';
import {IAction} from '../../components/FilehubMultipleActionsBar';
import {Tr} from '@symfonia-ksef/locales/keys';
import FilehubDesktopMultipleActionsItems from '../../components/FilehubDesktopMultipleActionsItems';
import {Text} from '@symfonia/symfonia-ksef-components';
import {FormattedMessage} from 'react-intl';
import {intl} from '../../../root/IntlProvider';
import {SharesType} from '../../table/FilehubShareTable';

type Props = {
  selectedAll?: boolean;
  selectedSharesLength: number;
  selectedShares: SharesType[];
  setSelectedShares: React.Dispatch<React.SetStateAction<SharesType[]>>;
  shares: SharesType[];
};

const FilehubShareMultipleActionsBar = ({selectedAll, selectedSharesLength, selectedShares, setSelectedShares, shares}: Props) => {
  const activeSharesLength = selectedShares.filter(share => share.isActive).length;
  const inActiveSharesLength = selectedSharesLength - activeSharesLength;

  const isActivateIconDisabled = activeSharesLength === selectedSharesLength;
  const isDisactivateIconDisabled = inActiveSharesLength === selectedSharesLength;

  const changeStatus = (newStatus: boolean) => {
    console.log(newStatus);
  };

  const deleteShares = () => {
    console.log('deleting shares');
  };

  const selectAllShares = () => {
    setSelectedShares(shares);
  };

  const unSelectAllShares = () => {
    setSelectedShares([]);
  };

  const actions: IAction[] = [
    {
      color: isActivateIconDisabled ? IconColor.GREY_400 : IconColor.GREEN_600,
      disabled: isActivateIconDisabled,
      key: Tr.enable,
      icon: IconSvg.LINK,
      onClick: () => changeStatus(true),
    },
    {
      color: isDisactivateIconDisabled ? IconColor.GREY_400 : IconColor.GREEN_600,
      disabled: isDisactivateIconDisabled,
      key: Tr.disable,
      icon: IconSvg.LINK_OFF,
      onClick: () => changeStatus(false),
    },
    {
      color: IconColor.RED_500,
      key: Tr.deleteCategory,
      icon: IconSvg.DELETE_OUTLINE,
      onClick: deleteShares,
    },
  ];

  return (
    <div className="w-full flex justify-between items-center bg-grey-50 rounded-[5px] p-[8px_16px]">
      <div>
        <Text small className="font-semibold pr-[8px] border-r-[1px] border-grey-400">
          {selectedSharesLength}&nbsp;
          <FormattedMessage id={Tr.elementsSelectedFromTheList}/>
        </Text>
        <ButtonTertiary
          className="text-[0.75em] mx-[8px]"
          onClick={selectedAll ? unSelectAllShares : selectAllShares}
          size={ButtonTertiarySize.SM}
          text={intl.formatMessage({id: selectedAll ? Tr.uncheck : Tr.selectAll})}
        />
      </div>
      <FilehubDesktopMultipleActionsItems actions={actions}/>
    </div>
  );
};

export default FilehubShareMultipleActionsBar;
