import {Tr} from './translationKeys';

export const pl: Record<Tr, string> = {
  appTitle: 'Symfonia KSeF',
  logout: 'Wyloguj',
  active: 'Aktywny',
  deactivated: 'Dezaktywowany',
  pending: 'Oczekuje',
  rejected: 'Odrzucony',
  salesDocumentsTab: 'Sprzedażowe',
  purchaseDocumentsTab: 'Zakupowe',
  forWho: 'Dla kogo',
  eArchiveManager: 'Symfonia KSeF',
  goBackToEarchive: 'Wróć do Listy dokumentów',
  goBackToAdministrationList: 'Wróć do Listy Administracji',
  tenantsList: 'Lista organizacji',
  usersList: 'Lista użytkowników',
  companiesList: 'Lista firm',
  settings: 'Ustawienia',
  open: 'Otwórz',
  authentication: 'Uwierzytelnienie',
  earchiveTenants: 'Organizacje w Symfonia KSeF',
  getCurrentPermissionsFromKsef: 'Trwa pobieranie aktualnych uprawnień z Krajowego Systemu e-Faktur.',
  pendingSendToKsef:
    'Trwa wysyłanie dokumentów do Krajowego Systemu e-Faktur. Zamknięcie zakładki przerwie wysyłkę dokumentów.',
  sendToKsefSendingError:
    'Nie wszystkie dokumenty zlecone do wysyłki do Krajowego Systemu e-Faktur, zostały wysłane z sukcesem. Sprawdź status dokumentów w tabeli.',
  genericError: 'Wystąpił niespodziewany błąd, spróbuj ponownie.',
  submissionInProgress: 'Procesujemy Twoje zgłoszenie',
  submissionSent:
    'Twoje zgłoszenie zostało wysłane do Symfonii.{br} W przeciągu 2 dni roboczych otrzymasz powiadomienie o decyzji na maila:',
  createTenantForm_Header: 'Dane organizacji',
  createTenantForm_Description:
    'Nie masz utworzonej organizacji w Symfonia KSeF. Podaj poniższe dane aby ją utworzyć i móc korzystać z aplikacji do zarządzania dostępami firm i użytkowników do nowych funkcjonalności (np.KSeF).',
  createTenantForm_CloudConsent: '<a>Warunki użytkowania Programu w chmurze</a>',
  createTenantForm_PersonalDataConsent:
    'Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych Symfonii.',
  createTenantForm_PrivacyPolicyConsent:
    'Poprzez przesłanie wypełnionego formularza oświadczam, że zapoznałam/-łem się i akceptuję <a>Politykę Prywatności</a>.',
  createTenantForm_CompanyRepresentationConsent:
    'Oświadczam, że jestem uprawniony/-a do działania w imieniu i ze skutkiem dla podmiotu, który reprezentuję.',
  createTenantForm_MarketingEndDeviceConsent:
    'Wyrażam zgodę na używanie przez Symfonię telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, dla celów marketingu bezpośredniego zgodnie z art. 172 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800).',
  createTenantForm_MarketingEmailConsent:
    'Wyrażam zgodę na przesyłanie do mnie przez Symfonię informacji handlowych drogą elektroniczną na podany wyżej adres e-mail, zgodnie z Ustawą z dnia 18 lipca 2002r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2002r. Nr144, poz. 1204 z późniejszymi zmianami).',
  createTenantForm_PersonalDataForPartnersConsent:
    'Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych partnerów Symfonii, których lista dostępna jest <a>tutaj</a>.',
  createTenantForm_Summary:
    'Po zatwierdzeniu tworzenia organizacji, Twoje zgłoszenie zostanie przesłane do Symofnii do akceptacji.',
  createTenantForm_Submit: 'Utwórz organizację',
  createTenantForm_RequiredConsentsHandler: 'Zaznacz wszystkie obowiązkowe',
  addCompanyButton: 'Dodaj firmę',
  addTenantButton: 'Dodaj organizacje',
  saveCompanyButton: 'Zapisz firmę',
  addUserButton: 'Dodaj użytkownika',
  saveUserButton: 'Zapisz użytkownika',
  actions: 'Akcje',
  yes: 'Tak',
  no: 'Nie',
  save: 'Zapisz',
  continueButton: 'Nie zapisuj',
  edit: 'Edytuj',
  password: 'Hasło',
  acceptTenant: 'Akceptuj',
  discardTenant: 'Odrzuć',
  cancelButton: 'Anuluj',
  cancelChangesButton: 'Anuluj zmiany',
  dictionary: 'Słownik',
  saveTenantButton: 'Zapisz zmiany',
  activateTenantButton: 'Aktywuj',
  deactivateTenantButton: 'Dezaktywuj',
  removeButton: 'Usuń',
  admin: 'Admin:',
  editCompanyForm_ContributorCheckbox: 'Admin organizacji (<strong>{name}</strong>)',
  nipTooltip_Content: 'Numer NIP powinien być wpisany bez znaków specjalnych oraz liter.',
  peselTooltip_Content: 'Numer PESEL powinien być wpisany bez znaków specjalnych oraz liter.',
  tenantCreateTooltip_Header: 'Czym jest organizacja?',
  tenantCreateTooltip_Content:
    'Organizacja to główna firma, na którą założone jest konto w module KSeF. Organizacja może być założona, do zarządzania jedną firmą (Przykład: Firma ABC jest główną i jedyną firmą do zarządzania). Organizacja może być założona do zarządzania wieloma firmami (Przykład: Biuro rachunkowe, które zarządza wieloma firmami).',
  subscriptionNumberTooltip_Header: 'Gdzie znajdę numer subskrypcji?',
  subscriptionNumberTooltip_Content: 'Numer subskrypcji umieszczony jest na fakturze oraz na koncie klienta w BOKu.',
  phoneNumberTooltip_Header: 'Format numeru telefonu',
  phoneNumberTooltip_Content:
    'Numer telefonu, składający się z 9 cyfr powinien być wpisany bez spacji, znaków specjalnych oraz liter np. 123456789.',
  companyKeyTooltip_Content:
    'Klucz firmy to numer, który jest potrzebny do powiązania firmy z organizacją. To pozwoli użytkownikom powiązanej firmy na korzystanie z faktur ustrukturyzowanych.{br}{br} Skopiuj klucz firmy, aby użyć go w opcjach administracyjnych, desktopowych modułów Symfonia.',
  companyKeyTooltip_Header: 'Po co mi klucz firmy?',
  tenantKeyTooltip_Content:
    'Klucz organizacji to numer, który jest potrzebny do powiązania firmy z organizacją. To pozwoli użytkownikom powiązanej firmy  na korzystanie z faktur ustrukturyzowanych.',
  tenantKeyTooltip_Header: 'Po co mi klucz organizacji?',
  ksefActionTooltip_Content:
    'Nie można wykonać tej operacji. Musisz najpierw uwierzytelnić się w Krajowym Systemie e-Fakur.',
  ksefDownloadTooltip_Header: 'Dlaczego nie mogę pobrać z KSeF?',
  ksefDownloadTooltip_Content:
    'Dokumenty z Krajowego Systemu e-Faktur do Symfonia KSeF, można pobrać tylko z konta, które jest uwierzytelnione i posiadające uprawnienia w Krajowym Systemie e-Faktur. Jeśli opcja ta jest nieaktywna znaczy to, że musisz uwierzytelnić konto. Przejdź do {link}',
  noInvoiceReadCredentialKsefDownloadTooltip_Header: 'Dlaczego nie mogę pobrać faktur z KSeF?',
  noInvoiceReadCredentialKsefDownloadTooltip_Content:
    'Dokumenty z Krajowego Systemu e-Faktur do Symfonia KSeF może pobrać jedynie Użytkownik uwierzytelniony oraz posiadający odpowiednie uprawnienia. Jeśli opcja ta jest nieaktywna, może oznaczać to między innymi: przekroczony limit pakietu, brak aktywnego pakietu, brak uwierzytelnienia lub brak odpowiednich uprawnień dostępu do faktur',
  noInvoiceWriteKsefSendTooltip_Header: 'Dlaczego nie mogę wysłać faktur do KSeF?',
  noInvoiceWriteKsefSendTooltip_Content:
    'Dokumenty do Krajowego Systemu e-Faktur z Symfonia KSeF może wysłać jedynie Użytkownik uwierzytelniony oraz posiadający odpowiednie uprawnienia. Jeśli opcja ta jest nieaktywna, może oznaczać to między innymi: przekroczony limit pakietu, brak aktywnego pakietu, brak uwierzytelnienia, faktury zostały już przesłane do KSeF lub nie ma nadanych uprawnień do ich wysyłki.',
  invoiceSentToKsefDisabledCauseSchemaTooltip:
    'Nie można wysłać faktury do KSeF, ponieważ nie jest ona wystawiona w nowej strukturze logicznej FA(2).',
  notificationInvoicesNotFetchedTooltip_Content:
    'Przekroczenie liczby nieudanych prób automatycznego pobrania faktur z KSeF w ciągu doby z powodu problemów po stronie KSeF.',
  permissionRegisterButtonTooltip_Content:
    'Tabela prezentuje jedynie uprawnienia zdefiniowane w Symfonia KSeF. Mogą się one różnić w stosunku do tych nadanych w KSeF. Uprawnienia pobrane z KSeF znajdują się w zakładce "Aktualne uprawnienia KSeF"',
  permissionRegisterButtonTooltip_Header: 'Co znajdę na liście rejestr uprawnień?',
  companyName: 'Nazwa firmy',
  companyKey: 'Klucz firmy',
  registrationDate: 'Data zgłoszenia',
  status: 'Status',
  rejectedReason: 'Powód odrzucenia',
  decisionDate: 'Data decyzji',
  tenantName: 'Nazwa organizacji',
  subscriptionNumber: 'Numer subskrypcji',
  subscription: 'Numer subskrypcji',
  tenantKey: 'Klucz organizacji',
  NIP: 'NIP',
  PESEL: 'PESEL',
  deleteCompany: 'Usuń firmę',
  backButton: 'Powrót',
  userEmail: 'E-mail',
  userFirstName: 'Imię',
  userLastName: 'Nazwisko',
  userPhoneNumber: 'Telefon',
  userContactPhoneNumber: 'Telefon kontaktowy',
  organizationName: 'Nazwa',
  comment: 'Komentarz',
  company: 'Firma',
  addCompany: 'Dodaj firmę',
  deleteUser: 'Usuń użytkownika',
  usersTotal: 'Użytkownicy ogółem',
  companiesAssigned: 'Przypisane firmy',
  Contributor: 'Administrator',
  roleAssignment: 'Rola',
  selectCompany: 'Wybierz firmę',
  validation: 'Błąd Captcha.',
  invoiceIsDownloaded: 'Pobrane',
  invoiceOwnNumber: 'Numer własny',
  invoiceName: 'Nazwa pliku',
  invoiceDocumentType: 'Typ dokumentu',
  invoiceSender: 'Nadawca',
  invoicesenderNIP: 'NIP nadawcy',
  invoiceReceiver: 'Odbiorca',
  invoiceReceiverNIP: 'NIP odbiorcy',
  invoiceAmountGross: 'Kwota brutto',
  invoiceAmountNet: 'Kwota netto',
  invoiceAmountVat: 'Kwota VAT',
  invoiceCurrency: 'Waluta',
  invoiceKsefIssueDate: 'Data wystawienia w KSeF',
  invoiceKsefIssueDateFrom: 'Data wystawienia w KSeF od',
  invoiceKsefIssueDateTo: 'Data wystawienia w KSeF do',
  invoiceKsefDocumentNumber: 'Numer dokumentu w KSeF',
  invoiceIssueDate: 'Data wystawienia',
  invoiceKsefStatus: 'Status KSeF',
  postingStatus: 'Status dekretacji',
  postingUnsavedChangesDialogTitle: 'Masz niezapisane zmiany',
  postingUnsavedChangesDialog: 'Masz niezapisane zmiany na dekerecie. Czy chcesz je zapisać?',
  registrationNumber: 'Numer ewidencyjny',
  purchaseDocuments: 'Dokumenty zakupowe',
  salesDocuments: 'Dokumenty sprzedażowe',
  newCompany: 'Nowa firma',
  companyDetails: 'Szczegóły firmy',
  newUser: 'Nowy użytkownik',
  ok: 'OK',
  operationSuccess: 'Operacja została wykonana poprawnie',
  cannotDeleteCompanyDialogTitle: 'Nie możesz usunąć firmy',
  cannotDeleteCompanyDialog:
    'Do firmy przypisane są dokumenty (np. faktury), dlatego {br} nie możesz jej usunąć. W pierwszym kroku musisz usunąć dokumenty {br} przypisane do firmy, aby móc usunać ją z Symfonia KSeF.',
  captchaErrorDialogTitle: 'Błąd formularza',
  captchaErrorDialog:
    'Nie udało się wysłać formularza. Proces zakładania {br} organizacji został przerwany. Spróbuj ponownie wysłać formularz.',
  tryAgain: 'Spróbuj ponownie',
  generalDetails: 'Dane ogólne',
  userDetails: 'Dane użytkownika',
  tenantDetails: 'Dane organizacji',
  rejectTenantProposal: 'Odrzucanie wniosku organizacji',
  rejectTenantProposalComment:
    'Odrzucasz wniosek o założenie organizacji. Jeśli chcesz dodać komentarz dla zgłaszającego, edytuj poniższe pole tekstowe.',
  commentForUser: 'Komentarz dla użytkownika',
  authorization: 'Uwierzytelnienie',
  authorizationStatus: 'Status uwierzytelnienia w KSeF MF',
  companyManagement: 'Zarządzanie firmą',
  authorizationKSeF: 'Uwierzytelnianie w Krajowym Systemie e-Faktur',
  authorizationKSeFDesc: 'Uwierzytelnij się w Krajowym Systemie e-Faktur w kontekście firmy {companyName} przy użyciu:',
  disconnectKSeF: 'ROZŁĄCZENIE Z KSeF',
  disconnectKSeFDesc:
    'Aby rozłączyć się z KSeF musisz się napierw uwierzytelnić.{br}Po rozłączeniu nie będzie możliwe wysyłanie dokumentów do KSeF oraz ich pobieranie z KSeF.{br}{br}Wybierz sposób uwierzytelnienia:',
  disconnectKSeFTokenDesc:
    'W związku z wprowadzeniem tokena zewnętrznego, token nie zostanie wycofany z Krajowego Systemu e-Faktur',
  authorizedKSeFDesc:
    '{authorizedFullName} w kontekście firmy {companyName}. Metoda uwierzytelniania: {br}{authorizedName}',
  authorizedKSeFPermissionPageDesc:
    'Nadaj uprawnienia do Krajowego Systemu e-Faktur w kontekście firmy {companyName} {br} Opcja dostępna tylko dla konta uwierzytelnionego i z nadanymi uprawnieniami.',
  profilZaufanyHeader: 'Profil zaufany',
  profilZaufanyContent: 'to podpis elektroniczny do podpisywania podań i wniosków składanych do podniotów publicznych.',
  authByTokenHeader: 'Token',
  authByTokenContent:
    'Należy wygenerować na stronie {link}. Rozłączenie nie oznacza unieważnienia tokena. Unieważnienie tokena jest dostępne na stronie {link}.',
  writeToken: 'Podaj token',
  token: 'Token',
  szafirHeader: 'Podpis kwalifikowany',
  szafirContent: 'jest podpisem, który ma moc prawną taką samą, jak podpis własnoręczny.',
  szafirErrorAuthentication:
    'Nie udało uwierzytelnić się w Krajowym Systemie e-Faktur. Sprawdź czy wybrano właściwy podpis kwalifikowany dla danej firmy i spróbuj ponownie.',
  szafirErrorUnexpected: 'Nie udało się aktywować podpisu kwalifikowanego.',
  szafirErrorNoExtension: `Nie posiadasz rozszerzenia 'Szafir SDK Web' w swojej przeglądarce. W celu instalacji udaj się <extLink>tutaj</extLink>.`,
  szafirErrorNoHostApp: `Nie posiadasz aplikacji 'Szafir Host' na swoim komputerze. Zainstaluj ją, aby móc się uwierzytelnić podpisem kwalifikowanym.`,
  szafirErrorHostAppOutdated: `Posiadasz nieaktualną wersję aplikacji 'Szafir Host' na swoim komputerze. W celu aktualizacji udaj się <hostAppLink>tutaj</hostAppLink>.`,
  accessForbiddenTitle: 'Brak dostępu',
  accessForbiddenContent:
    'Została wykryta więcej niż 1 firma, do której masz dostęp.{br} Przypadek ten nie jest obsługiwany z poziomu aplikacji webowej.{br}{br}Otwórz Symfonia KSeF z poziomu Symfonii Handlu, po zalogowaniu się do konkretnej firmy.',
  getUpoPdf: 'Pobierz UPO (PDF)',
  getUpoXml: 'Pobierz UPO (XML)',
  getUPOActionTooltip:
    'Pobranie UPO możliwe jest jedynie dla faktur wysłanych do KSeF, posiadających status "Zatwierdzone".',
  downloadInvoicePdf: 'Pobierz fakturę (PDF)',
  getInvoiceXml: 'Pobierz fakturę (XML)',
  downloadInvoice: 'Pobierz fakturę',
  sendToKSeF: 'Wyślij do KSeF',
  downloadPdf: 'Pobierz PDF',
  zoomIn: 'Powiększ',
  zoomOut: 'Pomniejsz',
  postInvoice: 'Dekretuj',
  exportToSymfoniaFinancesAndAccountancy: 'Pobierz do Finanse i Księgowość',
  exportToSymfoniaTrade: 'Pobierz do Handlu',
  exportToSymfoniaTrade_Tooltip: 'Akcja dostępna tylko po przejściu z aplikacji Symfonia Handel.',
  newPermission: 'Nowe uprawnienie',
  newPermissions: 'Nowe uprawnienia',
  downloadFromKsef: 'Pobierz z KSeF',
  downloadFromKsefByDates: 'Pobierz z KSeF z wyborem dat',
  downloadAllFromKsef: 'Pobierz z KSeF wszystko',
  editPermission: 'Detale uprawnień',
  permissions: 'Uprawnienia',
  operation: 'Operacja',
  dateOfOperation: 'Data operacji',
  permissionsName: 'Nazwa własna',
  PermissionsFor: 'Uprawnienia dla',
  PermissionFor: 'Uprawnienie dla',
  AddPermissions: 'Nadaj uprawnienia',
  AddPermission: 'Nadaj uprawnienie',
  CurrentKSeFPermissions: 'Aktualne uprawnienia w KSeF',
  RegisteredPermissionsSymfonyKSeF: 'Rejestr uprawnień w Symfonia KSeF',
  SyncWithKSeF: 'Synchronizuj z KSeF',
  syncWithKSeFTooltip: 'Dane prezentowane z dnia: {date} godziny: {hour}. Kliknij <b>Synchronizuj z KSeF</b>, aby zaktualizować dane.',
  permissionNameHeader: 'Po co mi nazwa własna?',
  permissionNameContent: 'Nazwę własną przypisujesz do NIPu lub PESELu co ułatwi Ci pracę na liście uprawnień.',
  deletePermissionTitle: 'Usunąć uprawnienia?',
  deletePermissionContent:
    'Po usunięciu uprawnień osoba lub firma nie będzie mogła korzystać z KSeF. Czy na pewno chcesz usunąć uprawnienia?',
  deletePermissionTableActionContent: 'Usuwasz uprawnienia dla {identifier}. Czy kontynuować operację?',
  addPermissionError: 'Wystąpił błąd podczas dodawania uprawnień. Spróbuj ponownie później.',
  addPermissionSuccess:
    'Zgłoszono do Krajowego Systemu e-Faktur nowe uprawnienie. Gdy Ministerstwo Finansów odpowie, uprawniona osoba będzie mogła wykonywać operacje w KSeF.',
  deletePermissionSuccess: 'Usunięcie uprawnień zostało zlecone.',
  editPermissionSuccess: 'Edycja uprawnień została zgłoszona do Krajowego Systemu e-Faktur.',
  CredentialsInvoiceRead: 'Odbieranie faktur',
  CredentialsInvoiceWrite: 'Wystawianie faktur',
  CredentialsManage: 'Zarządzanie uprawnieniami',
  CredentialsRead: 'Odczyt uprawnień',
  CredentialsSelfInvoicing: 'Samofakturowanie',
  getUpoError:
    'Nie udało się pobrać UPO z Krajowego Systemu e-Faktur. Spróbuj ponownie później lub rozłącz obecną sesję.',
  actionError: 'Błąd wykonywania akcji. Spróbuj ponownie później.',
  postingInvoicesActionError: 'Błąd wykonywania akcji. Spróbuj ponownie później.',
  getUpoMissingError: 'Nie udało się pobrać UPO z Krajowego Systemu e-Faktur. {reason}.',
  getInvoiceXmlError: 'Nie udało się pobrać faktury XML. Spróbuj ponownie.',
  getUpoFileError: 'Nie udało się pobrać pliku UPO.',
  getInvoicesFromKSeFSuccess:
    '{manualCancellation}Liczba pobranych dokumentów z Krajowego Systemu e-Faktur wynosi: {count}. Lista dokumentów została zaktualizowana.',
  getInvoicesFromKSeFPartialSuccess:
    '{manualCancellation}Nie udało sie pobrać części faktur. Komunikat otrzymany z ministerialnej strony Krajowego Systemu e-Faktur: {ksefDescription}. Liczba pobranych dokumentów z Krajowego Systemu e-Faktur wynosi: {count}. Lista dokumentów została zaktualizowana.',
  getInvoicesFromKSeFCancelled: 'Ręczne przerwanie pobierania: ',
  getInvoicesFromKSeFError:
  '{manualCancellation}Próba pobrania dokumentów z Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  getInvoicesFromKSeFNoPackage:
    '{manualCancellation}Limit dostępny w ramach pakietu został wykorzystany. Liczba pobranych faktur wynosi: {count}.',
  getInvoicesFromKSeFNoPackageTitle: 'Nie pobrano wszystkich faktur z KSeF',
  getInvoicesFromKSeFUpToDate:
    '{manualCancellation}Nie ma żadnych faktur do pobrania. Twoja lista dokumentów jest aktualna.',
  getInvoicesFromKSeFNoData: '{manualCancellation}Nie pobrano żadnych faktur',
  sendManyToKSeFSuccess:
    'Faktury zostały wysłane do Krajowego Systemu e-Faktur. Ich liczba wynosi: {count}. Zmiana statusu faktur widoczna jest w tabeli z dokumentami w kolumnie "Status KSeF".',
  sendToKSeFSuccess:
    'Faktura została wysłana do Krajowego Systemu e-Faktur. Zmiana statusu faktury widoczna jest w tabeli z dokumentami w kolumnie "Status KSeF".',
  sendManyToKSeFInProgress:
    'Zlecono wysyłkę faktur do Krajowego Systemu e-Faktur. Ich liczba wynosi: {count}. Po analizie w KSeF, zmiana statusu na zatwierdzony lub odrzucony będzie widoczna w tabeli z dokumentami w kolumnie "Status KSeF".',
  sendToKSeFInProgress:
    'Zlecono wysyłkę faktury do Krajowego Systemu e-Faktur. Po analizie w KSeF, zmiana statusu na zatwierdzony lub odrzucony będzie widoczna w tabeli z dokumentami w kolumnie "Status KSeF".',
  autoSendToKSeFSuccess:
    'Faktury zostały wysłane w tle do Krajowego Systemu e-Faktur. Ich liczba wynosi: {count}. Zmiana statusu faktur widoczna jest w tabeli z dokumentami w kolumnie "Status KSeF".',
  autoSendToKSeFInProgress: '',
  autoSendToKSeFError:
    'Próba wysłania w tle dokumentów do Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  sendToKSeFError:
    'Próba wysłania dokumentu do Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  sendManyToKSeFError:
    'Próba wysłania dokumentów do Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  noPermissionToWrite: 'Brak uprawnień do wysłania faktur do Krajowego Systemu e-Faktur.',
  disconnectWithKSeF: 'Rozłącz z KSeF',
  rejectDisconnectWithKSeF: 'Anuluj rozłączanie',
  disconnectWithKSeFTitle: 'ROZŁĄCZYĆ Z KSEF?',
  disconnectWithKSeFContent:
    'Połączenie jest nawiązane przez token. Po rozłączeniu nie będzie możliwe wysyłanie i pobieranie faktur z Krajowego Systemu e-Faktur.{br} Czy chcesz kontynuować?',
  disconnectWithKSeF_error: 'Wystąpił błąd podczas rozłączania z KSeF. Spróbuj ponownie później.',
  disconnectWithKSeF_success: 'Poprawnie rozłączono z KSeF.',
  tenantAdminInfo: 'Administrator posiada dostęp do wszystkich firm w danej organizacji',
  bulkActions: 'Akcje zbiorcze',
  missingUpoTitle: 'Nie udało się pobrać UPO',
  missingUpoContent:
    'Nie udała się próba pobrania UPO dla {from} z {to} zaznaczonych dokumentów. {br}Możesz zobaczyć listę dokumentów, dla których UPO nie udało się pobrać, aby uzyskać informację jaki był tego powód.',
  privacyPolicy: 'Polityka cookies i prywatności',
  regulations: 'Regulamin',
  help: 'Pomoc',
  contact: 'Kontakt',
  copyrightSymfonia: 'Copyright {symbol} Symfonia {currentYear}',
  changePassword: 'Zmień hasło',
  connect: 'Połącz',
  errorAuthorizationWithToken: 'Nie udało uwierzytelnić się w Krajowym Systemie e-Faktur. Spróbuj ponownie.',
  companyNotFoundTitle: 'Nie znaleziono firmy!',
  companyNotFoundDesc: 'Firma nie została znaleziona, ponieważ nie istnieje lub została usunięta.',
  goBackToCompaniesList: '< Wróć do listy firm',
  userNotFoundTitle: 'Nie znaleziono użytkownika!',
  userNotFoundDesc: 'Użytkownik nie został znaleziony, ponieważ nie istnieje lub został usunięty.',
  goBackToUsersList: '< Wróć do listy użytkowników',
  eArchiveNoAccessTitle: 'Brak dostępu do Symfonia KSeF',
  eArchiveNoAccessDesc:
    'Nie posiadasz dostępu do modułu Symfonia KSeF, ponieważ żadna firma nie jest przypisana do twojego konta.',
  companyInactiveTitle: 'Firma jest nieaktywna!',
  companyInactiveDesc:
    'Nie posiadasz dostępu do tej firmy, ponieważ organizacja, do której należy została dezaktywowana.',
  tenantNotFoundTitle: 'Nie znaleziono organizacji!',
  tenantNotFoundDesc: 'Organizacja nie została znaleziona, ponieważ nie istnieje lub została usunięta.',
  goBackToTenantsList: '< Wróć do listy organizacji',
  bokErrorTitle: 'Moduł BOK nie mógł zostać uruchomiony!',
  bokErrorDesc: 'Wystąpił problem z pobraniem danych niezbędnych do uruchomienia modułu BOK.',
  myEnvironmentsErrorTitle: 'Aplikacja nie mogła zostać uruchomiona!',
  myEnvironmentsErrorDesc: 'Wystąpił problem z pobraniem danych niezbędnych do uruchomienia aplikacji.',
  inactiveTenantTitle: 'Organizacja jest nieaktywna!',
  inactiveTenantDesc: 'Twoja organizacja została dezaktywowana.',
  tenantRejectedTitle: 'Wniosek odrzucony',
  tenantRejectedDesc:
    'Twój wniosek o utworzenie organizacji został odrzucony. Szczegóły zostały wysłane na podany adres e-mail.',
  allDocuments: 'Wszystkie dokumenty',
  toDownload: 'Do pobrania',
  downloaded: 'Pobrane',
  toSend: 'Do wysłania',
  sent: 'Wysłane',
  gridErrorMessage: 'Wystąpił problem z pobieraniem danych, spróbuj ponownie.',
  showDocumentsList: 'Pokaż listę dokumentów',
  documentsList: 'Lista dokumentów',
  missingInvoiceReason_PROCESSING: 'Wysłany do Krajowego Systemu e-Faktur, nie ma wystawionego UPO',
  missingInvoiceReason_ERROR: 'Wystawiony dokument nie jest wysłany do Krajowego Systemu e-Faktur',
  missingInvoiceReason_NOT_SENT: 'Wystawiony dokument nie jest wysłany do Krajowego Systemu e-Faktur',
  missingInvoiceReason_NOT_EXISTS: 'Brak numeru dokumentu w bazie',
  missingInvoiceReason_UPO_NOT_GENERATED: 'W trakcie wysyłania do Krajowego Systemu e-Faktur',
  missingInvoiceReason_VALIDATION_ERROR: 'Dokument odrzucony przez Krajowy System e-Faktur',
  missingInvoiceReason_INTERRUPTED_SENDING: 'Wystawiony dokument nie jest wysłany do Krajowego Systemu e-Faktur',
  missingInvoiceReason_INTERRUPTED_PENDING:
    'Brak możliwości odświeżenia statusu dokumentu w Krajowym Systemie e-Faktur',
  missingInvoiceReason_OCR: 'Brak UPO dla danego typu faktury',
  missingInvoiceReason_SCHEMA_ERROR: 'Krajowy System e-Faktur uniemożliwił wygenerowanie UPO dla danego dokumentu',
  missingInvoiceReason_NO_SESSION_REFERENCE_NUMBER: 'Faktura nie została wysłana do Krajowego Systemu e-Faktur bezpośrednio z Symfonia KSeF',
  createTenantTitle: 'Tworzenie organizacji',
  saveChanges: 'Zapisz zmiany',
  registrationTenant: 'Zgłoszenie organizacji',
  registrationTenantReject: 'Zgłoszenie organizacji - {span}',
  authImpossibleWithoutNip: 'NIE MOŻESZ SIĘ UWIERZYTELNIĆ',
  authImpossibleWithoutNipTEXT:
    'Krajowy System e-Faktur od Ministerstwa Finansów, nie wspiera firm założonych na PESEL. Oznacza to, że nie możesz wysyłać faktur ustrukturyzowanych ani pobierać ich przez Symfonia KSeF.',
  azureWafPolicyError: 'Używasz zastrzeżonych metod.',
  forceEditPermissionTitle: 'Nadpisać uprawnienia?',
  forceEditPermissionContent:
    'Dla tego numeru NIP/PESEL istnieją już nadane uprawnienia w Krajowym Systemie e-Faktur. Nadając uprawnienia nadpiszesz już istniejące. Kontynuować nadawanie uprawnień?',
  forceEditButton: 'Kontynuuj',
  terminateKSeFSession: 'Rozłącz obecną sesję',
  terminateKSeFSessionInProgress:
    'Rozpoczęto próbę rozłączenia sesji. Po poprawnym przetworzeniu żądania, sesja zostanie rozłączona.',
  terminateKSeFSessionSuccess: 'Rozłączenie sesji przebiegło pomyślnie.',
  terminateKSeFSessionError: 'Próba rozłączenia sesji zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  syncPermissionKSeFInProgress:
  'Rozpoczęto próbę synchronizacji uprawnień z KSeF',
  syncPermissionKSeFError: 'Próba synchronizacji uprawnień z KSeF zakończyła się niepowodzeniem',
  syncPermissionKSeFSuccess: 'Synchronizacja uprawnień z KSeF zakończyła się sukcesem',
  sessionKSef: 'SESJA w Krajowym Systemie e-Faktur',
  sessionStatusTooltip_Header: 'Po co mi możliwość rozłączania sesji?',
  sessionStatusTooltip_Content:
    'UPO dla dokumentów może być pobrane {br} tylko z zamkniętych sesji. Należy rozłączyć {br} bieżącą sesję w Krajowym Systemie e-Faktur {br} aby móc pobrać UPO dla dokumentów {br} wysłanych podczas jej trwania.',
  companyExist_Header: 'FIRMA JUŻ ISTNIEJE',
  companyExist_Content:
    'Istnieje już firma o tych samych danych w Symfonii Administracja w ramach wybranej organizacji. Czy chcesz kontynuować dodawanie firmy?',
  continue: 'Kontynuuj',
  getInvoicesFromKSeFStarted:
    'Rozpoczęto pobieranie dokumentów, co może zająć to dłuższą chwilę. Pamiętaj o odświeżeniu strony Symfonia KSeF, aby móc zobaczyć wszystkie pobrane dokumenty.',
  companyPendingTitle: 'NIE MASZ PRZYPISANEJ FIRMY',
  companyPendingeDesc:
    'Nie znaleźliśmy żadnej firmy przypisanej do Ciebie. {br} Aby móc korzystać z modułu Symfonia KSeF musisz mieć przypisaną firmę.{br} {br} Skontaktuj się ze swoim administratorem Symfonia KSeF.',
  notifications: 'Notyfikacje',
  emailNotification: 'Ustawienia notyfikacji e-mail',
  emailNotificationContent:
    'Włączone notyfikacje e-mail będą informować Cię o każdej operacji wykonanej w Symfonia KSeF przez wszystkich użytkowników przypisanych do danej {company} organizacji. {br} {br} Adres e-mail, na który będą wysyłane notyfikacje {span}',
  AUTHORIZATION_OF_COMPANY_SUCCEEDED: 'Uwierzytelnienie użytkownika w KSeF',
  DOWNLOAD_INVOICES_FROM_K_SE_F: 'Pobranie faktur z KSeF',
  SEND_INVOICES_TO_K_SE_F: 'Wysyłka faktur do KSeF',
  DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED: 'Ręczne pobranie faktur z KSeF',
  AUTO_SEND_INVOICES_TO_K_SE_F_SUCCEEDED: 'Wysyłka faktur w tle do KSeF',
  AUTO_DOWNLOAD_INVOICES_FROM_K_SE_F_SUCCEEDED: 'Pobranie faktur z KSeF w tle',
  USER_UNAUTHORIZED_IN_KSEF: 'Nieudane pobranie faktur spowodowane uprawnieniami',
  DOWNLOAD_UPO_SUCCEEDED: 'Pobranie UPO',
  PERMISSIONS_EDITED: 'Edycja uprawnień',
  INVOICES_NOT_FETCHED: 'Nieudane pobranie faktur w tle z KSeF',
  SEND_INVOICES_TO_K_SE_F_SUCCEEDED: 'Wysyłka faktur do KSeF',
  COMPANY_CREATED_IN_TENANT: 'Dodanie firmy',
  COMPANY_DELETED: 'Usunięcie firmy',
  COMPANY_INFO_EDITED: 'Edycja danych firmy',
  TENANT_DEACTIVATED: 'Dezaktywacja organizacji',
  TENANT_INFO_EDITED: 'Edycja danych organizacji',
  USER_ASSIGNED_TO_COMPANY: 'Przypisanie firmy do osoby',
  COMPANY_CREATED_IN_ORGANIZATION: 'Dodanie firmy',
  ORGANIZATION_INFO_EDITED: 'Edycja danych organizacji',
  emailNotificationError: 'Nie udało się zapisać notyfikacji.',
  modulSwitchAdmin: 'Przejdź do Symfonia Administracja',
  modulSwitchEarchive: 'Przejdź do Symfonia KSeF',
  switchTenantPermissionTitle: 'PRZEŁĄCZASZ ORGANIZACJĘ',
  switchTenantPermissionContent: 'Przełączasz się do organizacji: {company} {br}Czy chcesz kontynuować?',
  switchCompanyPermissionTitle: 'PRZEŁĄCZASZ FIRMĘ',
  switchCompanyPermissionContent: 'Przełączasz się do firmy: {br} {company} {br}Czy chcesz kontynuować?',
  switchTenantContinueButton: 'Kontynuuj',
  switchTenantCancelButton: 'Anuluj',
  backgroundInvoiceDownload: 'Automatyczne pobieranie faktur',
  downloadSwitchOff:
    'Włączenie opcji pozwoli na automatyczną próbę pobrania dokumentów z Krajowego Systemu e-Faktur.{br}{br}Przy pierwszym włączeniu opcji dokumenty zostaną pobrane za okres 2 lat. Później będą pobierane od daty ostatniej faktury. W przypadku potrzeby zwiększenia zakresu -będzie trzeba ustawić go ręcznie na liście dokumentów.{br}{br}Opcja jest globalna, zaznaczenie jej sprawi, że inni użytkownicy przypisani do tej firmy, również będą mieli odświeżaną listę dokumentów w tle.',
  downloadSwitchOn:
    'Automatyczna próba pobrania dokumentów z Krajowego Systemu e-Faktur odbywa się każdego dnia o wskazanej godzinie. {br} Funkcja jest zastosowana dla wszystkich użytkowników w danej firmie.',
  downloadSwitchOnInterval:
    'Automatyczna próba pobrania dokumentów z Krajowego Systemu e-Faktur odbywa się zgodnie ze wskazanym przedziałem czasowym. {br} Funkcja jest zastosowana dla wszystkich użytkowników w danej firmie.',
  sendSwitchOn: 'Automatyczna próba wysłania dokumentów do Krajowego Systemu e-Faktur odbywa się każdego dnia o wskazanej godzinie. {br} Funkcja jest zastosowana dla wszystkich użytkowników w danej firmie.',
  sendSwitchInterval: 'Automatyczna próba wysłania dokumentów do Krajowego Systemu e-Faktur odbywa się zgodnie ze wskazanym przedziałem czasowym. {br} Funkcja jest zastosowana dla wszystkich użytkowników w danej firmie.',
  switchStateInfo: '{state} przez: {user} {date}',
  notAuthorizedAutoInvoiceError: 'Nie posiadasz uprawnień do zmiany automatycznego pobierania faktur.',
  autoFetchInvoiceTooltip_Content: 'Aby ustawić pobieranie w tle, należy posiadać uprawnienia do odbierania faktur.',
  autoFetchInvoiceTooltip_Header: 'Brak uprawnień',
  tenant: 'Organizacja',
  checkAll: 'Zaznacz wszystko',
  adminInformation: 'Admin ma przypisywane automatycznie wszystkie firmy.',
  assignAllAdminsOnCompanyCreatedInfo:
    'Firma zostanie automatycznie przypisana do wszystkich adminów w tej organizacji.',
  serverTypeQuestion: 'Do jakiego serwera Krajowego Systemu e-Faktur chcesz połączyć zakładaną firmę?*',
  serverTypeAnnotation:
    'Wyboru nie można zmienić po założeniu firmy. Istnieje możliwość założenia ponownie firmy i podłączenie jej do innego serwera.',
  tooltipProd_Content:
    'Połączenie z serwerem produkcyjnym Krajowego Systemu e-Faktur będzie jednoznaczne z wprowadzeniem faktur do obiegu prawnego.',
  tooltipProd_Header: 'Serwer PROD',
  tooltipDemo_Content:
    'Połączenie z serwerem demonstracyjnym Krajowego Systemu e-Faktur pozwoli przetestować działanie systemu bez żadnych skutków prawnych oraz dostosować procesy biznesowe do nadchodzących zmian w zakresie faktur ustrukturyzowanych.',
  tooltipDemo_Header: 'Serwer DEMO MF',
  serverDEMO: 'Serwer DEMO MF',
  serverPROD: 'Serwer PROD MF',
  typeOfConnection: 'Firma podłączona do serwera: {typeOfConnection}',
  DEMO: 'DEMO MF',
  PROD: 'PROD MF',
  connectionWithServer: 'Połączenie z serwerem KSeF',
  salesInvoices: 'Faktury sprzedażowe',
  purchaseInvoices: 'Faktury zakupowe',
  salesInvoices_Tooltip: 'Liczba faktur sprzedaży w danym miesiącu.',
  purchaseInvoices_Tooltip: 'Liczba faktur zakupowych niewysłanych do zewnętrznych systemów.',
  redirectToHub: 'Zmień organizację',
  autoPosting: 'Auto Dekretacje',
  contractor: 'Kontrahent',
  EXTERNAL: 'Zakup',
  INTERNAL: 'Sprzedaż',
  invoiceBound: 'Faktura sprzedaży/zakupu',
  details: 'Szczegóły',
  postingStatus_no_action: '-',
  postingStatus_posted: 'Zadekretowano',
  postingStatus_decree_sentFilter: 'Pobrane do FK',
  postingStatus_decree_sent: 'Wysłane do FK',
  postingStatus_decree_downloaded: 'Pobrano do FK',
  postingStatus_notAllowed: 'Brak',
  postingStatus_posting: 'W trakcie',
  postingStatus_postingFailed: 'Do ponownej dekretacji',
  postingStatus_postingIncomplete: 'Częściowa dekretacja',
  postingStatus_readyForPosting: 'Do dekretacji',
  postingStatus_undefined: 'Brak',
  postingStatus_incomplete: 'Częściowa dekretacja',
  postringConfiguration_no_assignment: 'Brak',
  sendToFK: 'Wyślij zaznaczone do FK',
  sendAndSave: 'Zapisz i uaktualnij mechanizm',
  organizationCreated: 'Stworzono organizację ',
  organizationAccepted: 'Aktywowano organizację ',
  organizationRejected: 'Odrzucono organizację ',
  organizationDeactivated: 'Dezaktywowano organizację ',
  organizationDeleted: 'Usunięto organizację ',
  dateFrom: 'Data od:',
  date: 'Data:',
  to: 'do',
  toLabel: 'do:',
  from: 'od',
  selectSpecificDate: 'Wybierz konkretną datę',
  selectDate: 'Wybierz datę',
  multiSelectPlaceHolder: 'Wyszukaj i zaznacz',
  searchCompany: 'Szukaj firmy',
  filterLabel: 'Filtruj',
  tableLabel: 'Filtry',
  closeLabel: 'Zamknij',
  clearAllLabel: 'Wyczyść',
  clearFiltersLabel: 'Wyczyść filtry',
  result: 'Wynik',
  Foreign: 'Zakupowe',
  Own: 'Sprzedażowe',
  Downloaded: 'Pobrane',
  ToDownload: 'Do pobrania',
  Sent: 'Wysłane',
  ToSend: 'Do wysłania',
  InvoiceNumber: 'Numer własny',
  DocumentTypes: 'Typ dokumentu',
  InvoicePostingStatuses: 'Status dekretacji',
  IssuerNames: 'Nadawca',
  IssuerNip: 'NIP nadawcy',
  RecipientNames: 'Odbiorca',
  RecipientNip: 'NIP odbiorcy',
  KsefNumber: 'Numer dokumentu w KSeF',
  KsefNumbers: 'Numer',
  DocumentNumber: 'Numer dokumentu',
  KSeFStatuses: 'Status KSeF',
  NetValue: 'Kwota netto',
  VatValue: 'Kwota VAT',
  GrossValue: 'Kwota brutto',
  SearchSpecificNumber: 'Wyszukaj konkretny numer',
  DateOfIssue: 'Data wystawienia',
  emptyField: '(puste)',
  account: 'Konto',
  category: 'Kategoria',
  wn: 'Wn:',
  ma: 'Ma:',
  positions: 'Pozycje:',
  decreeNotFound:
    'Nie udało się pobrać szczegółów dekretu.{br}Wejdź na szczegóły innego dekretu lub spróbuj ponownie później.',
  decreeFailed: 'Nie udało się zadekretować wybranych dokumentów. Spróbuj ponownie później.',
  decreeTableFailed: 'Nie udało się pobrać listy dekretów.{br} Odśwież stronę lub spróbuj ponownie później.',
  reloadPage: 'Odśwież',
  goBack: 'Wróć do listy dokumentów',
  decreeEditFailed: 'Nie udało się wprowadzić zmian do dekretu. Spróbuj ponownie.',
  decreeSaveFailed: 'Nie udało się zapisać zmian na dekrecie. Spróbuj ponownie.',
  decreeSaveAndRefreshFailed:
    'Nie udało się zapisać zmian oraz zaktualizować mechanizmu do przyszłych dekretacji. Spróbuj ponownie później.',
  operationFailed: 'Operacja nie została wykonana poprawnie',
  posting: 'Dekretacje',
  postingConfiguration: 'Konfiguracja Automatycznych Dekretacji',
  postingNotActive:
    'Otrzymujesz tymczasowy dostęp do pełnej <b>wersji testowej</b>.{br} Aktywuj mechanizm Automatycznych Dekretacji, aby w pełni korzystać z modułu w ramach firmy <b>{companyName}</b>.',
  postingNotAvailable:
    'Automatyczne dekretacje przy wykorzystywaniu sztucznej inteligencjii ułatwiają pracę, przy rozliczaniu faktur. Algorytm sczytuje faktury i na bazie informacji przygotowuje dekretacje dla tych faktur. Tobie pozostaje tylko zatwierdzić zaproponowane dekretacje. Pamiętaj algorytm uczy się cały czas, więc będzie się doskonalić i będzie dla Ciebie doskonałym pomocnikiem w bieżacej pracy.',
  adminContact: 'Skontaktuj się z Administratorem',
  postingActivation: 'Aktywacja mechanizmu',
  activatingProcess: 'Sprawdzanie konfiguracji agenta...',
  repeatActivation: 'Ponów aktywację',
  postingActive:
    '<b>{title}</b> {br} {br} W tabeli znajdują się kategorie wraz z kontami do nich przypisanymi. Na te konta będą dekretowane pozycje z faktury, które zostaną przyporządkowane automatycznie do wybranej kategorii. Zdefiniuj kategorie w ramach firmy <b>{nameCompany}</b>.',
  allCategories: 'Wszystkie kategorie',
  basicCategories: 'Kategorie podstawowe',
  ownCategories: 'Kategorie własne',
  categoryType: 'Rodzaj kategorii',
  accountKUP: 'Konto KUP',
  accountNKUP: 'Konto NKUP',
  settlements: 'Rozrachunki i VAT',
  addCategory: 'Dodaj kategorię',
  categoryNameLengthValidation: 'Nazwa kategorii nie może być dłuższa niż 70 znaków',
  addCategoryDescription:
    'Dodana kategoria będzie użyta w mechanizmie automatycznych dekretacji i od tego momentu wszystkie przyszłe dekretacje będą ja uwzgledniać. Odśwież dekrety, na których chcesz, aby pojawiła się nowa kategoria.',
  openCategory: 'Zobacz zawartość kategorii',
  changePositionCategory: 'Zmień kod wybranej pozycji:',
  DownloadInvoicesReport_Tooltip_Header: 'Dlaczego nie mogę pobrać raportu?',
  DownloadInvoicesReport_Tooltip_Content:
    'Raport dostępny do pobrania tylko dla przefiltrowanych danych, zawierających minimum 1 rekord. Maksymalna liczba rekordów w raporcie to 15 000.',
  DownloadInvoicesReport_Tooltip_Content_NoFilter:
    'Raport dostępny do pobrania tylko dla danych zawierających minimum 1 rekord. Maksymalna liczba rekordów w raporcie to 15 000.',
  gridNoData: 'Brak danych',
  gridNoFilteredData: 'Brak wyników spełniających dane kryteria filtrowania',
  addAnother: 'Dodaj kolejny',
  addAnotherNip: 'Dodaj kolejny NIP',
  number: 'Numer',
  NIPPesel: 'NIP/ PESEL',
  agentConfigureCheck: 'Sprawdzanie konfiguracji agenta',
  desktopConnectionCheck: 'Sprawdzanie połączenia firmy z desktop',
  agentUnavailableErrorTitle: 'Nieudana aktywacja',
  agentUnavailableErrorContent:
    'Nie można kontynuować aktywacji usługi automatycznych dekretacji.{br}Przejdź do administracji i upewnij się, że firma została połączona z modułem chmurowym AppSymfonia oraz że Agent został skonfigurowany.{br}{br}Jeśli konfiguracja została poprawnie wykonana, ponów proces aktywacji.',
  externalFinancialYearsPending: 'Pobranie lat finansowych',
  externalFinancialYearsErrorTitle: 'Nieudane pobranie lat finansowych',
  externalFinancialYearsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalContractorsPending: 'Pobranie kontrahentów',
  externalContractorsErrorTitle: 'Nieudane pobranie kontrahentów',
  externalContractorsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalAccountsPending: 'Pobranie planu kont',
  externalAccountsErrorTitle: 'Nieudane pobranie planu kont',
  externalAccountsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalDictionariesPending: 'Pobranie słowników',
  externalDictionariesErrorTitle: 'Nieudane pobranie słowników',
  externalDictionariesErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalDictionaryElementsPending: 'Pobranie elementów słowników',
  externalDictionaryElementsErrorTitle: 'Nieudane pobranie elementów słowników',
  externalDictionaryElementsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalVatRegistryElementsPending: 'Pobranie rejestrów VAT',
  externalVatRegistryElementsErrorTitle: 'Nieudane pobranie rejestrów VAT',
  externalVatRegistryElementsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  externalDocumentTypeElementsPending: 'Pobranie typów dokumentów',
  externalDocumentTypeElementsErrorTitle: 'Nieudane pobranie typów dokumentów',
  externalDocumentTypeElementsErrorContent:
    'Nie można kontynuować aktywacji usługi{br}automatycznych dekretacji.{br}{br}Spróbuj ponownie później.',
  initializationCompleting: 'Kończenie inicjalizacji',
  buyPackage: 'Kup pakiet',
  package: 'Pakiet',
  autopostingInitializationFailed:
    'Aktywacja automatycznych dekretacji się nie powiodła. Przejdź na zakładkę {autopostingUrl} , aby dowiedzieć się więcej.',
  autopostingRedirectButton: 'Dekretacje',
  accounts: 'Plan kont',
  plan: 'Plan',
  lastActualizationFromFK: 'Ostatnia aktualizacja z FK:',
  PackageInactiveClarification:
    'Twój pakiet wygasł dnia {date}. Archiwalne faktury zakupowe zostały usunięte z Twojego konta. {buyPackageMessage} {messageForNonAdminUser}',
  PackageInactiveBuyPackage:
    'Aby móc korzystać z Symfonia KSeF oraz połączenia z Krajowym Systemem e-Faktur, konieczny jest zakup nowego pakietu.',
  PackageInactiveContactWithAdmin: 'W tym celu skontaktuj się z Administratorem Biznesowym w Twojej organizacji.',
  SubscriptionNotFoundMessage:
    'Aby móc korzystać ze wszystkich funkcjonalności Symfonia KSeF oraz połączenia z Krajowym Systemem e-Faktur, konieczny jest zakup pakietu. {messageForNonAdminUser}',
  AccessDenied: 'Brak dostępu',
  SubscriptionNotFound: 'Brak aktywnego pakietu',
  yourPackage: 'Twój pakiet',
  activeUntil: 'Aktywny do',
  activePackageUntil: 'Aktywny pakiet do',
  packageIncludes: 'W tym pakiecie dostępne:',
  noData: 'Brak danych',
  invoicesCount: 'Liczba faktur',
  memory: 'Pamięć',
  used: 'Wykorzystane',
  availableInvoices: 'Dostępna liczba faktur',
  availableSpace: 'Dostępna pamięć',
  usedSpace: 'Wykorzystane miejsce',
  requestLimit: 'Przekroczony limit zapytań do KSeF.',
  requestLimitContent: 'Osiągnięto limit zapytań w Krajowym Systemie e-Faktur.',
  connectionWithServer_Tooltip_Demo:
    'Połączenie z serwerem demonstracyjnym Krajowego Systemu e-Faktur pozwala przetestować działanie systemu bez żadnych skutków prawnych oraz dostosować procesy biznesowe do nadchodzących zmian w zakresie faktur ustrukturyzowanych.',
  connectionWithServer_Tooltip_Prod:
    'Połączenie z serwerem produkcyjnym Krajowego Systemu e-Faktur jest jednoznaczne z wprowadzeniem faktur do obiegu prawnego.',
  usageIconExpiring_Tooltip_Header: 'Wykorzystanie pakietu',
  usageIconExpired_Tooltip_Header: 'Pakiet nieaktywny',
  usageIconUsed_Tooltip_Header: 'Pakiet wykorzystany',
  usageIconExpiringSoonPercentage_Tooltip_Header: 'Pozostało do wykorzystania mniej niż {percentageLeft}% pakietu. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconExpiryDate_Tooltip_Content:
    'W dniu {expiryDate} kończy się data ważności zakupionego pakietu. Po tym terminie wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconInvoiceLimit_Tooltip_Content:
    'Pozostała do wykorzystania liczba faktur z zakupionego pakietu: {invoiceCount}. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconDiskSpaceLimit_Tooltip_Content:
    'Pozostałe do wykorzystania miejsce w pamięci z zakupionego pakietu: {diskSpace}. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconExpiryDateAndInvoiceLimit_Tooltip_Content:
    'W dniu {expiryDate} kończy się data ważności zakupionego pakietu. Pozostała do wykorzystania liczba faktur z zakupionego pakietu: {invoiceCount}. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconExpiryDateAndDiskSpaceLimit_Tooltip_Content:
    'W dniu {expiryDate} kończy się data ważności zakupionego pakietu. Pozostałe do wykorzystania miejsce w pamięci z zakupionego pakietu: {diskSpace}.Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageAllConditionsExpired_Tooltip_Content:
    'W dniu {expiryDate} kończy się data ważności zakupionego pakietu. Pozostało do wykorzystania {invoiceCount} faktur oraz {diskSpace} miejsca w pamięci z zakupionego pakietu. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageAllConditionsExpiredUser_Tooltip_Content:
    'W dniu {expiryDate} kończy się data ważności zakupionego pakietu. Pozostało do wykorzystania mniej niż {percentageLeft}% pakietu. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconInvoiceLimitAndDiskSpaceLimit_Tooltip_Content:
    'Pozostało do wykorzystania {invoiceCount} faktur oraz {diskSpace} miejsca w pamięci z zakupionego pakietu. Po wykorzystaniu limitu wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usageIconExpired_Tooltip_Content: 'Aktualny pakiet stracił ważność. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  usagePackageExpiredFromDate: 'Aktualny pakiet stracił ważność. Od dnia {dateFrom} wysyłanie i odbieranie dokumentów z KSeF będzie ograniczone do 5 faktur co 30 dni. Aby móc w pełni korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  categoryName: 'Kategoria',
  categoryDefault: 'Podstawowa',
  categoryCustom: 'Własna',
  range: 'Zakres',
  amount: 'Kwota',
  searchAmount: 'Wyszukaj konkretną kwotę',
  usageIconUsed_Tooltip_Content:
    'Limit dostępny w ramach pakietu został wykorzystany. Aby móc korzystać z połączenia z Ministerstwem Finansów konieczny jest zakup nowego pakietu.',
  description_STORAGE: '{value} pamięci',
  description_INVOICE: '{value} faktur (łącznie zakupowych i sprzedażowych)',
  subscriptionFormTitle: 'Informacje o subskrypcji dla organizacji',
  subcriptionNumber: 'Numer subskrypcji',
  serialNumber: 'Numer seryjny',
  serialNumberTooltip_Content: 'Numer seryjny znajduje się w e-mailu z informacją o opłaceniu pakietu.',
  serialNumberTooltip_Header: 'Gdzie znajdę numer seryjny?',
  serialNumberInUseModal_Content:
    'Podany numer seryjny jest już przypisany do innej organizacji. Upewnij się, że został on poprawnie skopiowany z wiadomości e-mail o aktywacji pakietu. Informacje na temat numeru seryjnego dostępne są także na Twoim koncie w {link}, w zakładce MOJE PRODUKTY > LICENCJE.',
  serialNumberInUseModal_Header: 'Numer seryjny został już użyty',
  serialNumberWrongModal_Content:
    'Podany numer seryjny jest nieprawidłowy. Upewnij się, że został on poprawnie skopiowany z wiadomości e-mail o aktywacji pakietu. Informacje na temat numeru seryjnego dostępne są także na Twoim koncie w {link}, w zakładce MOJE PRODUKTY > LICENCJE.',
  serialNumberWrongModal_Header: 'Nieprawidłowy numer seryjny',
  serialNumberGenericMessage:
    '{message} Upewnij się, że numer został poprawnie skopiowany z wiadomości e-mail o aktywacji pakietu. Informacje na temat numeru seryjnego dostępne są także na Twoim koncie w {link}, w zakładce MOJE PRODUKTY > LICENCJE.',
  acknowledge: 'Rozumiem',
  eBok: 'eBOK Symfonia',
  categoryContent: 'Lista kategorii',
  categoryContentTxt:
    'Zawartość kategorii zawiera słowa oraz wyrażenia przypisane do wybranych z listy kategorii. Dane wyrażenie może być przypisane tylko do jednej kategorii.',
  postingSettings: 'Ustawienia dekretacji',
  activeExpressions: 'Aktywne wyrażenia',
  archivedExpressions: 'Zarchiwizowane wyrażenia',
  addNewCategory: 'Dodaj nową kategorię ',
  expression: 'Wyrażenie',
  add: 'Dodaj',
  previousCategory: 'Poprzednia kategoria',
  user: 'Użytkownik',
  current: 'Aktualne',
  archival: 'Archiwalne',
  wrongSerialNumberError: 'Podany numer seryjny jest nieprawidłowy.',
  usedSerialNumberError: 'Podany numer seryjny jest już przypisany do innej organizacji.',
  categoryAccountSaveFailed: 'Nie udało się zapisać zmian w konfiguracji. Spróbuj ponownie.',
  LoadingPackageStatisticError:
    'Wystąpił błąd podczas pobierania danych o aktywnym pakiecie. Spróbuj odświeżyć stronę.',
  inactiveFrom: 'Nieaktywny od',
  accessDeniedDesktopTitle: 'BRAK DOSTĘPU',
  accessDeniedDesktopDescription:
    'Nie posiadasz uprawnień do korzystania z modułu Symfonia KSeF. Skontaktuj się z Administratorem aplikacji w Twojej organizacji.',
  editCategory: 'Edytuj kategorię',
  writeCategoryName: 'Wpisz nazwę kategorii',
  categoryAdded: 'Dodano kategorię {categoryName}.',
  deleteCategory: 'Usuń kategorię',
  chooseAccount: 'Wybierz konto',
  choose: 'Wybierz',
  search: 'Szukaj',
  changeCategory: 'Zmień kategorię',
  settlementsTxt:
    'Wybierz konto dla rozrachunków oraz VAT, aby skonfigurować mechanizm dekretacji dla firmy {nameCompany}.',
  dateLabel: 'Data',
  changeCategoryNameHeader: 'Edytuj nazwę kategorii',
  categoryNameChanged: 'Zmieniono nazwę kategorii {oldName} na {name}.',
  categoryNameChangedFailed: 'Nie udało się zmienić nazwy kategorii.',
  ksefHealth_DOWN_tooltip_Content: 'Brak połączenia z Krajowym Systemem e-Faktur. {br} Ostatnia aktualizacja: {date}.',
  ksefHealth_UNKNOWN_tooltip_Content: 'Nieznany status Krajowego Systemu e-Faktur. {br} Ostatnia aktualizacja: {date}.',
  ksefHealth_OPERATIONAL_tooltip_Content:
    'Połączenie z Krajowym Systemem e-Faktur jest aktywne. {br} Ostatnia aktualizacja {date}.',
  ksefStatusError: 'Strona Krajowego Systemu e-Faktur nie odpowiada. Spróbuj ponownie później.',
  packageUsageTooltipHeader: 'Zużycie pakietu',
  packageUsageTooltipContent:
    'Zużycie pakietu obejmuje wysłanie lub odebranie faktur do/z Ministerstwa finansów oraz Faktury OCR.',
  use: 'Użyj',
  archive: 'Archiwizuj',
  saveAutoPostingSettings: 'Zapisano zmiany w mechanizmie automatycznych dekretacji.',
  settlementsSaveFailed:
    'Nie udało się zapisać zmian w mechanizmie automatycznych dekretacji. Spróbuj ponownie później.',
  categoryAlreadyExist: 'Kategoria już istnieje',
  categoryAlreadyExistTxt:
    'Nie możemy dodać kategorii, ponieważ istnieje już inna o takiej nazwie. Aby dodać kategorię, zmień jej nazwę.',
  change: 'Zmień',
  whatIfind: 'Co tutaj znajdę?',
  whatIConfigure: 'Co tutaj konfiguruję?',
  noDataToDisplay: 'Brak danych do wyświetlenia',
  deleteCategoryWithCategoryRulesHeader: 'Kategoria posiada przypisane wyrażenia',
  deleteCategoryWithCategoryRulesDescription:
    'Wybrana kategoria posiada dodane wyrażenia. Usuwając ją, usuniesz wszystkie przypisane do niej wyrażenia. Aby móc zachować wyrażenia przepisz je na inną kategorię.',
  moveCategoryRules: 'Przepisz wyrażenia',
  deleteCategoryWithCategoryRules: 'Usuń całą kategorię',
  deleteCategoryHeader: 'Usuwanie kategorii',
  deleteCategoryDescription:
    'Usuwasz kategorię, która nie posiada jeszcze żadnych przypisanych wyrażeń. Czy na pewno chcesz usunąć kategorię {categoryName}?',
  chooseNewCategoryDescription: 'Wybierz nową kategorię dla wyrażeń z usuwanej kategorii {categoryName}.',
  moveCategoryRulesAndRemoveCategory: 'Przepisz wyrażenia i usuń kategorię',
  moveToCategory: 'Przepisz do kategorii:',
  categoryRulesList: 'Lista wyrażeń:',
  categoryDeleted: 'Kategoria {categoryName} została usunięta.',
  categoryDeletedFailed: 'Nie udało się usunąć kategorii {categoryName}. Spróbuj ponownie później.',
  previewMode: 'Tryb z podglądem',
  type: 'Typ',
  PURCHASE: 'Zakupowe',
  SALES: 'Sprzedażowe',
  CategoryIds: 'Kategoria',
  AccountsKUP: 'Konto KUP',
  AccountsNKUP: 'Konto NKUP',
  InvoiceTypeGroup: 'Typ',
  missingKsefInvoicesTitle: 'NIE UDAŁO SIĘ POBRAĆ FAKTUR Z KSeF',
  resetStarted: 'Reset zainicjalizowany. Poczekaj, aż opcja rozruchu modułu pojawi się ponownie.',
  resetFailed:
    'Wystąpił błąd podczas aktywacji modułu Automatycznych Dekretacji. Możesz zgłosić problem za pomocą opcji <b>Wsparcie</b> lub poprzez infolinię.',
  permissionRegisterOperationGRANT: 'Nadanie uprawnień',
  permissionRegisterOperationREVOKE: 'Usunięcie uprawnień',
  permissionRegisterOperationUNDEFINED: '-',
  authSubTokenInProgress:
    'Trwa weryfikacja tokena w Krajowym Systemie e-Faktur. Po poprawnej weryfikacji informacja o statusie uwierzytelnienia pojawi się w zakładce {link}.',
  authSubTokenSuccess: 'Uwierzytelnienie za pomocą tokena w Krajowym Systemie e-Faktur przebiegło pomyślnie.',
  authSubTokenError:
    'Brak autoryzacji. Sprawdź czy token jest wygenerowany na środowisku {ksefEnv} Ministerstwa Finansów',
  authSubSignatureInProgress:
    'Próba uwierzytelnienia w Krajowym Systemie e-Faktur za pomocą podpisu kwalifikowanego została rozpoczęta. Po poprawnej weryfikacji informacja o statusie uwierzytelnienia pojawi się w zakładce {link}.',
  authSubSignatureSuccess:
    'Uwierzytelnienie za pomocą podpisu kwalifikowanego w Krajowym Systemie e-Faktur przebiegło pomyślnie.',
  authSubSignatureError:
    'Próba uwierzytelnienia za pomocą podpisu kwalifikowanego w Krajowym Systemie e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  authGeneralError: 'Błąd podczas próby uwierzytelnienia. Spróbuj ponownie później.',
  unAuthSubSuccess: 'Rozłączenie połączenia z Krajowym Systemem e-Faktur przebiegło pomyślnie.',
  unAuthSubError:
    'Próba rozłączenia z Krajowym Systemem e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  unAuthSubPending:
    'Rozpoczęto próbę rozłączenia z Krajowym Systemem e-Faktur. Po poprawnym przeprocesowaniu żądania, informacja o statusie uwierzytelnienia pojawi się w zakładce {link}.',
  permissionRegisterStatus_SendToKsef: 'Zatwierdzone',
  permissionRegisterStatus_RejectedByKsef: 'Odrzucone',
  permissionRegisterStatus_Pending: 'W trakcie weryfikacji',
  removePermissionButtonTooltip_Content: 'Usuń wszystkie nadane uprawnienia',
  deletePermissionMenuItemSuccess:
    'Zgłoszono usunięcie uprawnień dla {identifier} do Krajowego Systemu e-Faktur. Gdy Ministerstwo Finansów odpowie, uprawnienia znikną z listy "Aktualne uprawnienia KSeF".',
  selfInvoicingDescription:
    'Procedura samofakturowania pozwala na wskazanie podmiotów, które jako nabywcy mogą wystawiać faktury w imieniu podatnika (sprzedawcy). Należy wpisać NIP nabywcy, aby nadać uprawnienie do samofakturowania.',
  removeSelfInvoicing: 'Usuń samofakturowanie',
  pendingAuthorization: 'Trwa uwierzytelnianie w KSeF...',
  pendingUnauthorization: 'Trwa rozłączanie z KSeF...',
  deleteSelfInvoicingActionTitle: 'Usunąć samofakturowanie?',
  deleteSelfInvoicingActionContent: 'Usuwasz samofakturowanie dla {identifier}. Czy kontynuować operację?',
  addSelfInvoicingToExistingNIPError:
    'Nie udało się nadać samofakturowania dla tego numeru NIP, ponieważ takie uprawnienie już istnieje w Krajowym Systemie e-Faktur.',
  restoreExpression: 'Przywróć wyrażenie',
  deleteExpression: 'Usuń wyrażenie',
  postingSettingsListDescription: 'Poniższa lista pokazuje typy dokumentów z KSeF.',
  postingSettingsTitlePopover: 'Jak skonfigurować typ dokumentów?',
  postingSettingsTitlePopoverText:
    'Należy ustawić ogólny typ dokumentów oraz rejestr VAT dla faktur. Wszystkie dokumenty będą dekretowane na wybrany typ oraz rejestr VAT.',
  VAT: 'Faktura VAT',
  CORRECTIVE: 'Faktura korygująca VAT',
  SIMPLIFIED: 'Faktura uproszczona',
  ADVANCE_BILLING: 'Faktura zaliczkowa/rozliczająca',
  CORRECTIVE_ADVANCE_BILLING: 'Faktura korygująca zaliczkowa/rozliczająca',
  UNDEFINED: 'missing type',
  downloadInvoicesSubSuccess: 'Dokumenty z Krajowego Systemu e-Faktur zostały pomyślnie pobrane.',
  downloadInvoicesSubInProgress:
    'Rozpoczęto próbę pobrania dokumentów z Krajowego Systemu e-Faktur. Po poprawnym przetworzeniu żądania, faktury pojawią się na liście.',
  getInvoicesFromKSeFWithDateSuccess:
    'Liczba pobranych dokumentów z Krajowego Systemu e-Faktur z wybranego zakresu dat: {dateFrom} do {dateTo}, wynosi: {count}. Lista dokumentów została zaktualizowana.',
  addKSeFPermissionSuccess: 'Nadanie uprawnień w Krajowym Systemie e-Faktur przebiegło pomyślnie.',
  editKSeFPermissionSuccess: 'Edycja uprawnień w Krajowym Systemie e-Faktur przebiegła pomyślnie.',
  removeKSeFPermissionSuccess: 'Usunięcie uprawnień w Krajowym Systemie e-Faktur przebiegło pomyślnie.',
  addKSeFPermissionError:
    'Próba nadania uprawnień w Krajowym Systemie e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  editKSeFPermissionError:
    'Próba edycji uprawnień w Krajowym Systemie e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  removeKSeFPermissionError:
    'Próba usunięcia uprawnień w Krajowym Systemie e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  addKSeFPermissionInProgress:
    'Nadanie uprawnień zostało zgłoszone do Krajowego Systemu e-Faktur. Po poprawnym przetworzeniu żądania, uprawnienia pojawią się na liście {link}.',
  editKSeFPermissionInProgress:
    'Edycja uprawnień została zgłoszona do Krajowego Systemu e-Faktur. Po poprawnym przetworzeniu żądania, uprawnienia pojawią się na liście {link}.',
  removeKSeFPermissionInProgress:
    'Usunięcie uprawnień zostało zgłoszone do Krajowego Systemu e-Faktur. Po poprawnym przetworzeniu żądania, lista {link} zostanie zaktualizowana.',
  grantKSeFPermissionSubSuccess: 'Nadpisano uprawnienia. Lista {link} jest aktualna.',
  grantKSeFPermissionSubInProgress:
    'Zgłoszenie dotyczące zmiany uprawnień zostało przesłane do Krajowego Systemu e-Faktur. Po poprawnym przetworzeniu żądania, lista {link} zostanie zaktualizowana.',
  ksefConnectionError: 'Błąd połączenia z KSeF MF: "{description}". Spróbuj ponownie później.',
  grantKSeFPermissionSubError:
    'Próba zmiany uprawnień w Krajowym Systemie e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  subscriptionError:
    'Próba pobrania dokumentów z Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  getUpoSubInProgress: 'Rozpoczęto próbę wygenerowania UPO. Po poprawnym przetworzeniu żądania, UPO zostanie pobrane.',
  getUpoSubSuccess: 'Wygenerowanie UPO przebiegło pomyślnie.',
  getUpoSubPartialSuccess: 'Wygenerowanie UPO przebiegło pomyślnie dla {count} z {total}.',
  getUpoSubError: 'Próba wygenerowania UPO zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  expiredTokenTitle: 'BRAK AKTUALNEGO TOKENA',
  expiredTokenDescription:
    'Aby móc korzystać z pełnej funkcjonalności Symfonia KSeF uwierzytelnij się ponownie w Krajowym Systemie e-Faktur, za pomocą nowo wygenerowanego tokena, przechodząc do zakładki Uwierzytelnienie.',
  expiredTokenGoToAuthorize: 'Przejdź do uwierzytelnienia',
  decreesList: 'Lista dekretacji',
  Specific: 'Data',
  DateFrom: 'Data od',
  DateTo: 'Data do',
  ModifiedAt: 'Data',
  CategoryNames: 'Kategoria',
  UserIds: 'Użytkownik',
  AccountNKUPIds: 'Konto NKUP',
  AccountKUPIds: 'Konto KUP',
  PositionName: 'Wyrażenie',
  vehicles: 'Pojazdy',
  vehiclesConfiguration: 'Konfiguracja pojazdów',
  addVehicle: 'Dodaj pojazd',
  licencePlate: 'Numer rejestracyjny',
  writeLicencePlate: 'Wpisz numer rejestracyjny',
  licencePlateValueInputError: 'Błędny numer rejestracyjny. Rejestracja nie zawiera znaków specjalnych ani spacji.',
  VehicleUsageTypes: 'Rodzaj użytkowania',
  VehicleUsageType: 'Rodzaj użytkowania',
  RegistrationNumber: 'Numer ewidencyjny',
  PrivateVehicle: 'Pojazd prywatny',
  MixedVehicle: 'Pojazd w użytkowaniu mieszanym',
  CompanyVehicle: 'Pojazd służbowy',
  description: 'Opis',
  writeDescription: 'Wpisz opis',
  deleteVehicle: 'Usuń pojazd',
  editVehicle: 'Edytuj pojazd',
  registrationNumberPlate: 'Tablica rejestracyjna',
  deleteVehicleHeader: 'Usunąć pojazd?',
  deleteVehicleDescription: 'Czy napewno chcesz usunąć pojazd {registrationNumberPlate}?',
  deleteVehicleSuccess: 'Usunięto pojazd o numerze rejestracyjnym {registrationNumberPlate}.',
  deleteVehicleFailed: 'Nie udało się usunąć pojazdu {registrationNumberPlate}. Spróbuj ponownie później.',
  updateVehicleSuccess: 'Zapisano zmiany pojazdu.',
  updateVehicleFailed:
    'Nie udało się zapisać numeru rejestracyjnego {registrationNumberPlate}. Spróbuj ponownie później.',
  wsEventsHistory: 'Powiadomienia',
  wsEventsContent: 'Poniżej lista 100 ostatnich powiadomień wyświetlonych w systemie.',
  wsUnreadOnlyBtn: 'Tylko nieprzeczytane',
  wsMarkAsRead: 'Oznacz wszystko jako przeczytane',
  message: 'Komunikat',
  AuthorizeInKSeF: 'Autoryzacja podpisem w KSeF',
  AuthorizeInKSeFExternalToken: 'Autoryzacja tokenem w KSeF',
  UnauthorizedInKSeF: 'Odautoryzowanie podpisem w KSeF',
  UnauthorizedInKSeFExternalToken: 'Odautoryzowanie tokenem w KSeF',
  AutoUnauthorizedInKSeFExternalToken: 'Automatyczne odautoryzowanie w KSeF',
  DownloadInvoices: 'Pobieranie faktur',
  GetUPO: 'Pobieranie UPO',
  TerminateSession: 'Rozłączenie sesji',
  PermissionOperation: 'Operacje na uprawnieniach',
  SyncPermissionKSeF: 'Synchronizacja uprawnień',
  goToWSEvents: 'Kliknij ikonę dzwonka, aby przejść do listy powiadomień.',
  unreadMessageTooltip: 'Nieprzeczytane powiadomienia: {badgeNumber}',
  wsEventsGridNoUnreadData: 'Brak powiadomień',
  loginHubSetTenant: 'Lista firm w organizacji',
  loginHubChooseTenant: 'Wybierz organizację, aby zobaczyć jej listę firm',
  authorizedManagePermissionsOnlyPageDesc: `Nadaj uprawnienia do Krajowego Systemu e-Faktur w kontekście firmy {companyName}`,
  missingKsefInvoicesContentWithCount:
    'Liczba faktur, których nie udało się pobrać z Krajowego Systemu e-Faktur wynosi {range}. Rozwiń listę poniżej, aby sprawdzić powód.',
  getUPOResultTitle: 'UPO zostało wygenerowane',
  getUPOResultContent: 'Czy chcesz pobrać i zapisać wygenerowane dokumenty?',
  AutoFetchingInvoices: 'Automatyczne pobieranie faktur',
  downloadUPO: 'Pobierz UPO',
  showGettingInvoicesReport: 'Pokaż raport',
  unreadMessages: 'Liczba powiadomień wynosi: {count}. Przejdź do {link}, aby zobaczyć szczegóły.',
  wsNotificationsHistory: 'historii powiadomień',
  eventDate: 'Data',
  info: 'Informacja',
  error: 'Błąd',
  success: 'Sukces',
  showMessage: 'Pokaż komunikat',
  ksefAutoFetchingInvoicesSubSuccess:
    '{manualCancellation}Liczba pobranych w tle dokumentów z Krajowego Systemu e-Faktur wynosi: {count} . Lista dokumentów została zaktualizowana.',
  ksefAutoFetchingInvoicesPartialSubSuccess:
    '{manualCancellation}Nie udało sie pobrać części faktur. Komunikat otrzymany z ministerialnej strony Krajowego Systemu e-Faktur: {ksefDescription}. Liczba pobranych w tle dokumentów z Krajowego Systemu e-Faktur wynosi: {count}. Lista dokumentów została zaktualizowana.',
  ksefAutoFetchingInvoicesSubError:
    '{manualCancellation}Próba pobrania w tle dokumentów z Krajowego Systemu e-Faktur zakończyła się niepowodzeniem. Spróbuj ponownie później.',
  missingKsefAutoFetchInvoicesTitle: 'NIE UDAŁO SIĘ POBRAĆ W TLE FAKTUR Z KSeF',
  TerminateKSeFSessionButtonTooltip_Header: 'Tymczasowy brak możliwości rozłączenia sesji',
  TerminateKSeFSessionButtonTooltip_Content:
    'Trwa operacja związana z modyfikacją uprawnień w Krajowym Systemie e-Faktur. Rozłączenie sesji będzie możliwe po jej zakończeniu.',
  operationType: 'Typ operacji',
  COMPANY: 'Pojazd służbowy',
  MIXED: 'Pojazd w użytkowaniu mieszanym',
  PRIVATE: 'Pojazd prywatny',
  vehicleSaveError: 'Nie udało się dodać pojazdu. Spróbuj ponownie później.',
  vehicleSaveSuccess: 'Dodano pojazd {registrationNumberPlate} do konfiguracji samochodów.',
  licencePlateExistsInputError: 'Pojazd o numerze rejestracyjnym {licensePlate} już istnieje.',
  postingInitializationCompleted:
    'Aktywacja automatycznych dekretacji się powiodła. Wejdź na zakładkę {link}, aby skonfigurowac mechanizm dekretacji.',
  licencePlateLengthInputError: 'Numer rejestracyjny powinien zawierać od 4 do 8 znaków.',
  vehicleDescriptionLengthInputError: 'Opis powinien zawierać maksymalnie 70 znaków.',
  postingPartlyNotAvailableModalTitle: 'Część dokumentów nie zostanie zadekretowana',
  postingPartlyNotAvailableModalText:
    '{unavailableNumber} z {selectedNumber} wybranych faktur nie zostanie zadekretowania. Rozwiń listę, aby zobaczyć powód.',
  sentInvoicesForPostingSuccess:
    'Liczba wysłanych do dekretacji dokumentów to: {selected}. Po zakończeniu dekretacji, można sprawdzić szczegóły dekretacji w zakładce {link}.',
  readyForPosting: 'Do dekretacji',
  invoicePostingStatus: 'Status dekretacji',
  invoicesDatesHeader: 'Pobieranie dokumentów',
  fetchingInvoicesModalTooltipContent:
    'Jednorazowo możliwe jest pobranie faktur za dowolny okres 2 lat.{br}{br} Domyślnie ustawiony zakres dat, to 90 dni od daty bieżącej.{br}{br} Przed pobraniem upewnij się, czy posiadana przez Ciebie subskrypcja zawiera wystarczającą ilość faktur i miejsca.',
  firstFetchingInvoicesModalTooltipContent:
    'Jednorazowo możliwe jest pobranie faktur za dowolny okres 2 lat.{br}{br} Domyślnie ustawiony zakres dat, to 2 lata od daty bieżącej.{br}{br} Przed pobraniem upewnij się, czy posiadana przez Ciebie subskrypcja zawiera wystarczającą ilość faktur i miejsca.',
  autoFetchingInvoicesLink:
    'Włącz cykliczne pobierania faktur z KSeF MF w ustawieniach <a>Automatycznego pobierania faktur.</a>',
  invoicesDatesContent:
    'Domyślnie ustawiony zakres dat, to 90 dni od dzisiaj. Zmiana dat zwiększa ryzyko pominięcia pobrania brakujących w systemie faktur.',
  minDate: 'Data nie może być wcześniejsza od 01-01-2022.',
  maxDate: 'Data nie może być późniejsza od daty bieżącej.',
  invalidDate: 'Błędny format daty. Wpisz datę w formacie DD-MM-RRRR',
  outOfRange: 'Nieprawidłowy zakres dat.',
  download: 'Pobierz',
  inBackground: 'w tle',
  userCannotDeleteOwnersPermissions_Tooltip: 'Użytkownik nie może usunąć uprawnień właścicielskich.',
  userCannotEditOwnersPermissions_Tooltip: 'Użytkownik nie może edytować uprawnień właścicielskich.',
  ContractorId: 'Kontrahent',
  ContractorName: 'Kontrahent',
  ContractorIdentifier: 'NIP',
  Identifier: 'NIP',
  Nip: 'NIP',
  PostingDate: 'Data dekretacji',
  PostingStatus: 'Status',
  identifierSender: 'NIP nadawcy',
  documentType: 'Typ dokumentu',
  decre_sent: 'Pobrano do FK',
  incomplete: 'Częściowa dekretacja',
  INCOMPLETE: 'Częściowa dekretacja',
  noaction: '-',
  posted: 'Zadekretowano',
  POSTED: 'Zadekretowano',
  invoicePURCHASE: 'Faktura zakupu',
  invoiceSALES: 'Faktura sprzedaży',
  allDecrees: 'Wszystkie dekretacje',
  decreeReadyToSent: 'Do pobrania do FK',
  decree_sent: 'Pobrano do FK',
  DECREE_SENT: 'Wysyłanie do FK',
  DECREE_DOWNLOADED: 'Pobrano do FK',
  FAILED: 'Błąd dekretacji',
  NO_ACTION: '',
  publishInvoicesAction_error:
    'Wystąpił nieoczekiwany błąd. Nie powiodło się pobranie wybranych dokumentów do Symfonia Finanse i Księgowość. Spróbuj ponownie.',
  publishInvoicesAction_success:
    'Liczba pobranych dokumentów do Symfonia Finanse i Księgowość wraz z ich dekretem wynosi: {count}.',
  editDecree: 'Edytuj dekretację',
  refreshDecree: 'Odśwież dekret',
  invoiceWithoutDecreeModalTitle: 'Faktura nie posiada dekretu',
  invoiceWithoutDecreeModalText:
    'Faktura wybrana do pobrania do programu Symfonia Finanse i Księgowość nie posiada dekretacji. Po pobraniu faktury nie będzie można wykonać automatycznej dekretacji w programie Symfonia KSeF.',
  sum: 'Suma',
  nullDate: 'Nieprawidłowy zakres dat.',
  completeTheDate: 'Uzupełnij datę.',
  backToDecreeList: 'Powrót do listy dekretacji',
  invoiceWithTypeGroupAndNumber: '{invoiceTypeTranslation}: <strong>{number}</strong>',
  decreeStatus: 'Status dekretu:',
  hideDetails: 'Zwiń detale',
  invoiceWithTypeGroup: '{invoiceWithTypeGroup}',
  invoicePreview: 'Podgląd faktury',
  previewDecree: 'Podgląd dekretacji',
  showDetails: 'Rozwiń detale',
  contractorWithName: 'Kontrahent: {contractorWithName}',
  vatRegistry: 'Rejestr VAT*',
  vatRate: 'VAT',
  net: 'Netto',
  vatValue: 'Kwota VAT',
  gtu: 'GTU',
  vatRates: 'Stawki VAT',
  dateOfIssueWithValue: 'Data wystawienia: {dateOfIssueWithValue}',
  KSeFNumberWithValue: 'Numer w KSeF: {KSeFNumberWithValue}',
  identifierWithValue: 'Identyfikator: {identifierWithValue}',
  KSeFDateWithValue: 'Data KSeF: {KSeFDateWithValue}',
  currencyRateWithValue: 'Waluta/Kurs: {currencyRateWithValue}',
  addressWithValue: 'Ulica i numer: {addressWithValue}',
  goodsServicesRecivedDateWithValue: 'Data operacji gosp.: {goodsServicesRecivedDateWithValue}',
  invoiceTypeWithValue: 'Rodzaj faktury: {invoiceTypeWithValue}',
  cityWithValue: 'Miasto: {cityWithValue}',
  paymentTypeWithValue: 'Rodzaj Płatności: {paymentTypeWithValue}',
  zipCodeWithValue: 'Kod pocztowy: {zipCodeWithValue}',
  paymentDateWithValue: 'Termin płatności: {paymentDateWithValue}',
  chooseVatRegistries: 'Wybierz rejestr Vat',
  downloadToFK: 'Pobierz do FK',
  downloadTo: 'Pobierz do',
  requiredField: 'Pole wymagane',
  backgroundInvoiceSending: 'Automatyczne wysyłanie faktur sprzedażowych',
  dailyPeriodSwitchLabel: 'Codziennie o konkretnej godzinie',
  sendAtTime: 'Godzina wysyłki:',
  intervalPeriodSwitchLabel: 'Cyklicznie co wybrany przedział czasowy',
  sendInterval: 'Wysyłaj co:',
  hours: 'godziny.',
  invoiceSendSwitchTooltip:
    'Włączenie opcji pozwoli na automatyczną próbę wysłania dokumentów do Krajowego Systemu e-Faktur codziennie o konkretnej godzinie lub w wybranym cyklu godzinowym. Opcja jest globalna, zaznaczenie jej sprawi, że inni użytkownicy przypisani do tej firmy, również będą mieli odświeżaną listę dokumentów w tle.',
  whoActivated: 'Aktywowana',
  whoDeactivated: 'Dezaktywowana',
  pick: 'Wybierz:',
  autoSendInvoiceTooltip_Content: 'Aby ustawić wysyłanie w tle, należy posiadać uprawnienia do wystawiania faktur.',
  autoSendInvoiceTooltip_Header: 'Brak uprawnień',
  autoActionsInBackground: 'USTAWIENIA AKCJI W TLE',
  submitFormError: 'Nie udało się zapisać danych. Spróbuj ponownie później.',
  submitFormSuccess: 'Zapisanie danych przebiegło pomyślnie.',
  AutoSendingInvoices: 'Automatyczne wysyłanie faktur',
  SendingInvoices: 'Wysyłanie faktur',
  UploadInvoices: 'Import pliku',
  OcrInvoiceImported: 'Weryfikacja faktury',
  hours2: 'godzin.',
  choosePlaceholder: 'Wybierz',
  unknownNotificationType: 'Nieznana operacja',
  netValueTo: 'Kwota netto do',
  netValueFrom: 'Kwota netto od',
  vatValueTo: 'Kwota VAT do',
  vatValueFrom: 'Kwota VAT od',
  grossValueTo: 'Kwota brutto do',
  grossValueFrom: 'Kwota brutto od',
  PostingCreatedAtSpecific: 'Data dekretacji',
  PostingCreatedAtFrom: 'Data dekretacji od',
  PostingCreatedAtTo: 'Data dekretacji do',
  netValueSearchAmount: 'Kwota netto',
  vatValueSearchAmount: 'Kwota VAT',
  grossValueSearchAmount: 'Kwota brutto',
  decreeChangeSuccess: 'Zapisano zmiany dekretu.',
  configurationRequired: 'Wymagana konfiguracja',
  configurationRequiredMessage:
    'Należy aktywować i skonfigurować Automatyczne dekretacje, aby móc dekretować dokumenty. Przejdź do {link} w Zarządzaniu kontem.',
  decreeConfigure: 'Konfiguracji dekretacji',
  noCompanyAssigned_title: 'NIE MASZ PRZYPISANEJ FIRMY',
  noCompanyAssigned_content:
    'Niestety nie znaleźliśmy, żadnej firmy przypisanej do Ciebie. {br} Aby móc korzystać z Symfonia KSeF musisz mieć przypisaną firmę. {br}{br} Skontaktuj się ze swoim administratorem App Symofnia.',
  userNotAuthorized_title: 'BRAK DOSTĘPU',
  userNotAuthorized_content:
    'Niestety, nie posiadasz dostępu do firmy.{br}{br} Skontaktuj się z administratorem App Symfonia, aby nadać potrzebne dostępy lub wybierz inną firmę. {br} {br}',
  chooseCompanyRedirect: 'Przejdź do wyboru firm',
  nonEmptyField: 'Pole wyboru nie może być puste',
  ksefConnectionErrorMessage:
    'Operacja zakończyła się niepowodzeniem. Serwer Krajowego Systemu e-Faktur nie odpowiada. Spróbuj ponownie później.',
  defaultKsefErrorMessage: 'Wystąpił błąd podczas połączenia z KSeF.',
  notificationType: 'Rodzaj notyfikacji',
  amountsDoNotMatch: 'Kwoty nie bilansują się',
  decreeConfigurationRequiredModalTitle: 'Pierwsza konfiguracja mechanizmu{br} automatycznych dekretacji',
  decreeConfigurationRequiredModalText: 'Do pełnego skonfigurowania mechanizmu potrzeba:{br}',
  categoryAccountsRequiredText: 'Przypisać przynajmniej jedno konto KUP lub NKUP do kategorii dokumentów Zakupowych',
  settlementsRequiredText: 'Przypisać obowiązkowe konta w zakładce Rozrachunki i VAT',
  vatRegistriesRequiredText: 'Wybrać typ dokumentów oraz rejestr VAT dla obowiązkowych elementów',
  apTemporaryTestVersion: 'Tymczasowa wersja testowa',
  changeVehicleUsageType: 'Zmień rodzaj użytkowania',
  changeAccountType: 'Zmień typ konta na {accountType}',
  configureAutodecree: 'Edytuj konto w dekrecie',
  getPostingConfigurationStatusFailed: 'Nie udało się pobrać statusu dekretacji',
  saveConfigurationBlockedExplanation: 'Dlaczego nie mogę zapisać konfiguracji?',
  whyCantSaveConfigurationTxt: '{br} Aby móc zapisać zmiany należy wybrać konto dla minimum jednej kategorii.',
  whyCantSaveConfigurationTxtSettings:
    'Aby móc zapisać zmiany należy przypisać konto {br} do wymaganych kategorii. Oznaczone przez “*”.',
  ksefOperationError:
    'Operacja zakończyła się niepowodzeniem. Komunikat otrzymany z ministerialnej strony Krajowego Systemu e-Faktur: {ksefDescription}',
  ksefRequestLimit: 'Limit żądań osiągnięty. Spróbuj ponownie o: {dateTime}.',
  ksefRequestLimitTime: 'Limit przekroczony: {time}',
  useInDecree: 'Użyj w bieżacym dekrecie',
  next: 'Dalej',
  addToConfiguration: 'Dodaj do konfiguracji',
  CARD: 'KARTA',
  BANK_TRANSFER: 'PRZELEW BANKOWY',
  CASH: 'GOTÓWKA',
  CHECK: 'CZEK',
  CREDIT: 'KREDYT',
  MOBILE: 'MOBILNA',
  VOUCHER: 'BON',
  useInMechanism: 'Użyj w mechanizmie',
  addVehicleModalMessage:
    'Nowo dodany pojazd będzie użyty w mechanizmie automatycznych dekretacji i od tego momentu wszystkie przyszłe dekretacje będą go uwzględniać.',
  editVehicleModalMessage:
    'Zmiana rodzaju użytkowania oraz opisu będzie użyta w mechanizmie automatycznych dekretacji i od tego momentu wszystkie przyszłe dekretacje będą je uwzględaniać.',
  Lp: 'Lp.',
  amountWn: 'Kwota Wn',
  amountMa: 'Kwota Ma',
  addNewVehicle: 'Dodaj nowy pojazd',
  searchRegistrationNumber: 'Wyszukaj numer ewidencyjny',
  sessionIsNotTerminated: 'Trwają procesy w bieżącej sesji',
  sessionIsNotTerminatedMessage:
    'UPO można pobrać jedynie po zamknięciu sesji. W bieżącej sesji trwają procesy, których listę można sprawdzić poniżej. Kliknięcie na "Rozłącz sesję", sprawi, że po ich zakończeniu sesja zostanie zamknięta, a UPO pobrane automatycznie.',
  showOngoingSessionProcesses: 'Pokaż listę trwających procesów',
  ongoingSessionProcesses: 'Procesy, które trwają',
  waitAndDownload: 'Rozłącz sesję',
  abandon: 'Anuluj',
  registrationNumberCreatedMessage:
    'Otrzymano numery ewidencyjne dla wysłanych dokumentów do Symfonia Finanse i Księgowość.',
  registrationNumberCreatedErrorMessage:
    'Nie udało się otrzymać numerów ewidencyjnych dla wysłanych dokumentów do Symfonia Finanse i Księgowość',
  appVersion: 'Wersja systemu: {appVersion}',
  InvoicePostingStatusChanged: 'Status dekretacji faktury zmienił się',
  RegistrationNumberCreated: 'Numer ewidencyjny został nadany',
  accountType: 'Typ konta',
  chooseAccountType: 'Wybierz typ konta',
  createCategory: 'Dodaj kategorie',
  Id: 'Kategoria',
  RegistrationNumbers: 'Tablica rejestracyjna',
  uploadInvoice_tooltipContent: ' Opcja pozwala na dodanie pliku do Symfonia KSeF.',
  addFilesForUpload: 'Dodaj plik XML',
  uploadFilesWindowHeader: 'DODAJ PLIK XML',
  uploadFilesWindowContent: 'Dodaj faktury w postaci pliku XML. Rozmiar pliku nie może przekroczyć 1 MB.',
  uploadOneBoxContent: 'Przeciągnij i upuść pojedynczy plik tutaj',
  uploadManyBoxContent: 'Przeciągnij i upuść do 50 plików tutaj',
  swapTheFile: 'Zamień na plik z dysku',
  chooseFileFromDisk: 'Wybierz plik{i} z dysku',
  acceptedFiles: 'Wybrane pliki',
  rejectedFiles: 'Nieprawidłowy format plików, sprawdź poprawność plików przed dodaniem. {br}{br}{br}',
  addedFile: 'Dodany plik',
  addedFiles: 'Dodane pliki',
  numberOfAddedDocuments: 'Wczytane pliki {countDocuments}',
  endAddingFiles: 'Zakończ dodawanie',
  uploadOneError:
    'Próba zaimportowania pliku .xml zakończyła się niepowodzeniem. {reason}. Sprawdź i spróbuj ponownie.',
  uploadManyError:
    'Próba zaimportowania plików .xml zakończyła się niepowodzeniem. {reason}. Sprawdź i spróbuj ponownie.',
  uploadOneInvalidType: 'Nieprawidłowy format pliku',
  uploadOneExceed: 'Możliwy jest import jedynie pojedynczego pliku',
  uploadOneInvalidSize: 'Nieprawidłowy rozmiar pliku',
  uploadManyInvalidType: 'Nieprawidłowy format plików',
  uploadManyExceed: 'Możliwy jest import maksymalnie 50 plików',
  uploadManyInvalidSize: 'Nieprawidłowy rozmiar plików',
  uploadOneInvalidMixedReasons:
    'Przykładowe błędy: możliwy jest import jedynie pojedynczego pliku, nieprawidłowy format lub rozmiar pliku',
  uploadManyInvalidMixedReasons:
    ' Przykładowe błędy: możliwy jest import maksymalnie 50 plików, nieprawidłowy format lub rozmiar plików',
  missingUploadInvoicesTitle: 'Nie udało się zaimportować plików',
  missingUploadInvoicesContent:
    'Liczba plików, których nie udało się zaimportować wynosi {from} z {to}. Rozwiń listę poniżej, aby sprawdzić powód.',
  showMissingUploadInvoices: 'Lista powodów',
  showMissingUploadInvoicesButtonLabel: 'Rozwiń',
  uploadInvoiceSuccess: 'Zaimportowano plik .xml. Lista dokumentów została zaktualizowana.',
  uploadInvoiceError:
    'Próba zaimportowania pliku .xml zakończyła się niepowodzeniem. Sprawdź jego poprawność i spróbuj ponownie później.',
  uploadInvoicesSuccess:
    'Liczba zaimportowanych plików .xml wynosi: {count} . Lista dokumentów została zaktualizowana.',
  uploadInvoicesError:
    'Próba zaimportowania plików .xml zakończyła się niepowodzeniem. Sprawdź ich poprawność i spróbuj ponownie później.',
  uploadInvoiceInProgress:
    'Rozpoczęto próbę zaimportowania pliku .xml. Po poprawnym przetworzeniu żądania, faktura pojawi się na liście.',
  uploadInvoicesInProgress:
    'Rozpoczęto próbę zaimportowania plików .xml. Po poprawnym przetworzeniu żądania, faktury pojawią się na liście.',
  sendToFKUnavailable: 'Nie można pobrać do FK',
  sendToFKUnavailableTxt: 'Dekret ma nieuzupełniony Rejestr VAT. Uzupełnij i spróbuj ponownie.',
  allRadioLabel: 'Faktury zakupowe i sprzedażowe',
  internalRadioLabel: 'Faktury sprzedażowe',
  externalRadioLabel: 'Faktury zakupowe',
  autoFetchingSwitchTooltip:
    'Włączenie tej funkcji zapewnia cykliczne pobierania faktur z KSeF MF.{br}{br}<li><b>Podczas pierwszego pobierania faktur z KSeF MF</b> zostaną pobrane faktury maksymalnie za okres 2 lat do daty bieżącej. Definiując limit daty pobierania faktur, system rozpocznie pobieranie od wskazanej daty. Jeśli wprowadzony limit daty będzie wcześniejszy niż 2 lata od daty bieżącej, do systemu zostaną pobrane faktury wyłącznie za okres 2 lat.</li>{br}<li><b>Podczas kolejnego pobierania faktur z KSeF MF</b> system pobierze faktury od daty najnowszej faktury znajdującej się w systemie Symfonia KSeF.</li>{br}Przed włączeniem tej funkcji upewnij się, czy posiadana przez Ciebie subskrypcja jest wystarczająca.',
  dateLessThenStart: 'Data nie może być wcześniejsza od 01-01-2022',
  cantDownloadUpoTooltip:
    'Faktura nie została wysłana do Krajowego Systemu e-Faktur bezpośrednio z Symfonia KSeF. Aby pobrać UPO, zaloguj się do aplikacji Ministerstwa.',
  selfInvoicingUpoTooltip: 'UPO powiązane z samofakturowaniem może pobrać nabywca wysyłający fakturę do KSEF MF',
  missingCategoryLabel: 'Brak',
  changingDefaultDate: 'Zmiana dat może powodować ryzyko pominięcia faktur',
  returnLabel: 'Przywróć',
  missingAccountLabel: 'Brak',
  invoicesDatesHeaderNew: 'Pierwsze pobranie dokumentów ',
  invoicesDatesContentNew:
    'Domyślnie ustawiony zakres dat, to 01-01-2022 do dzisiaj, czyli data powstania Krajowego Systemu e-Faktur. Dzięki temu pobrane zostaną wszystkie faktury znajdujące się w KSeF. Zmiana dat zwiększa ryzyko pominięcia pobrania brakujących w systemie faktur.',
  previousMonth: 'Poprzedni miesiąc',
  nextMonth: 'Następny miesiąc',
  downloadInvoiceToFKSuccess: 'Dokument wraz z dekretem pobrany do Symfonia Finanse i Księgowość.',
  downloadInvoiceToFKInfo:
    'Rozpoczęto próbę wysyłania dokumentów do FK. Po poprawnym przetworzeniu żądania, status faktur się zmieni.',
  downloadInvoicesToFKSuccess:
    'Liczba pobranych dokumentów do Symfonia Finanse i Księgowość wraz z ich dekretem wynosi: {count}.',
  downloadInvoiceToFKError:
    'Wystąpił nieoczekiwany błąd. Nie powiodło się pobieranie dokumentu do Symfonia Finanse i Księgowość. Spróbuj ponownie.',
  downloadInvoicesToFKError:
    'Wystąpił nieoczekiwany błąd. Nie powiodło się pobieranie niektórych dokumentów do Symfonia Finanse i Księgowość. Spróbuj ponownie.',
  emptyDate: 'Data nie może być pusta',
  VAT_23: '23%',
  VAT_22: '22%',
  VAT_8: '8%',
  VAT_7: '7%',
  VAT_5: '5%',
  VAT_4: '4%',
  VAT_3: '3%',
  VAT_0: '0%',
  VAT_ZW: 'ZW',
  VAT_OO: 'OO',
  VAT_NP: 'NP',
  VAT_OSS: 'OSS',
  apTestConfigurationHeader: 'Wymagana konfiguracja',
  apTestConfigurationMsg:
    'Z przyjemnością przedstawiamy nasz nowy Produkt!{br}{br}Otrzymujesz tymczasowy dostęp do pełnej <b>wersji testowej</b>.{br}{br}<b>Automatyczne Dekretacje</b> to rozwiązanie wykorzystujące innowacyjne technologie: zaszyte w środowisku desktopowym, chmurowym i sztucznej inteligencji. Stworzone po to, aby jak najbardziej skrócić czas procesowania faktur poprzez wyeliminowanie ręcznych czynności w procesie księgowania. Narzędzie jest przez nas ciągle rozwijane. {br}{br}Zachęcamy do skorzystania z naszego nowego Produktu. Wystarczy, że skonfigurujesz mechanizm przechodząc do {configurationLink} w\u00a0Zarządzaniu kontem. {br}{br} Masz pytania? Chcesz zgłosić swoje spotrzeżenia? {writeUsLink}.',
  writeUs: 'Napisz do nas',
  exportToFinancesAndAccountancyPartlyNotAvailableModalTitle: 'Część dokumentów nie zostanie pobrana do FK',
  exportToFinancesAndAccountancyPartlyNotAvailableModalText:
    '{unavailable} z {selected} zaznaczonych dokumentów nie zostanie pobrana do FK. Nie można pobrać do FK dokumentów ze statusem "Pobrane do FK" oraz "Wysyłanie do FK".',
  addFilesForOcrUpload: 'Dodaj plik PDF, JPG, PNG lub TIFF',
  showDocumentsToVerification: 'Zobacz listę dokumentów do weryfikacji',
  ocrInvoiceTooltip: 'Dokument jest zweryfikowanym skanem dokumentu',
  invoicesUploadTooltip: 'Opcja pozwala na dodanie pliku do Symfonia KSeF.',
  invoicesUploadXMLTooltip:
    'Opcja pozwala na dodanie pliku .xml. Maksymalna dopuszczalna wielkość importowanego pliku to 1 MB.',
  invoicesUploadOcrTooltip:
    'Opcja ta pozwala na dodanie skanu dokumentu w formacie .jpg, .png, .pdf oraz .tiff, w wielkości do 50MB. Odczytany przez system dokument możesz zweryfikować.',
  ocrImportedToKSeFSuccess: 'Faktura została zweryfikowana i dodana do listy dokumentów.',
  ocrImportedToKSeFError: 'Weryfikacja faktury zakończyła się niepowodzeniem',
  pageNotFoundError: 'STRONA NIE ISTNIEJE',
  pageNotFoundTitle: 'Strona nie istnieje',
  pageNotFoundDescription:
    '{image}{br}Wystąpił błąd. Podana strona nie została odnaleziona. Upewnij się, że adres strony jest prawidłowy lub przejdź do {link}. {br}{br} Masz pytania? Chcesz zgłosić swoje uwagi? {br} {contactUs}',
  pageNotFoundRedirectButton: 'strony głównej',
  documentTypes: 'Typ dokumentów',
  autoPostingDocuments: 'Automatyczna dekretacja dokumentów',
  autoPostingDocumentsDescription:
    'Włączenie opcji spowoduje automatyczne dekretowanie{br}przyszłych dokumentów, które dopiero pojawią się w systemie.',
  confirmDocsBeforeSendingToFK: 'Zatwierdzaj dekrety przed wyslaniem do FK',
  confirmDocsBeforeSendingToFKDescription:
    'Włączenie opcji wymusza każdorazowo zatwierdzanie dekretu{br}przed wysłaniem do FK.',
  possibilityToAcceptAllDecrees: 'Możliwość akceptacji wszystkich dekretów.',
  lackOfPossibilityToAcceptOwnDecrees: 'Brak możliwości akceptacji własnych dekretów.',
  documentTypesTitle: 'Ustawienia typów dokumentów',
  postingSettingsTitle: 'Ustawienia dekretacji',
  documentTypesTitlePopover: 'Jak skonfigurować typ dokumentów?',
  documentTypesTitlePopoverText:
    'Należy ustawić ogólny typ dokumentów oraz rejestr VAT dla faktur. Wszystkie dokumenty będą dekretowane na wybrany typ oraz rejestr VAT.',
  saveSuccess: 'Zapisano zmiany.',
  saveError: 'Błąd zapisu. Spróbuj ponownie.',
  autoPostingSwitchStateInfo: '{state} przez {user} {date}',
  deactivatedSwitch: 'Dezaktywowana',
  sendToKsefExtendedModal:
    '{unavailableNumber} z {selectedNumber} wybranych faktur nie zostanie pobrana do FK. Rozwiń listę aby zobaczyć, powód.',
  PostInvoicesFailed: 'Błąd dekretowania faktur',
  postInvoicesFailedErrorMessage:
    'Nieudana dekretacja dokumentów: {invoiceNumbers}. Spróbuj ręcznie zadekretować dokumenty.',
  acceptDecree: 'Akceptuj dekretację',
  acceptDecreeDisableTooltip: 'Użytkownik nie może sam akceptować wykonych przez siebie dekretacji.',
  getCurrentEnvironmentFailed: 'Nie udało się pobrać ustawień aplikacji.',
  deleteInvoicesError: 'Próba usunięcia dokumentów zakończyła się niepowodzeniem.',
  deleteSingleInvoiceError: 'Próba usunięcia dokumentu zakończyła się niepowodzeniem.',
  deleteInvoicesSuccess: 'Usunięto wybrane dokumenty.',
  deleteInvoicesPartialSuccess:
    'Liczba usuniętych dokumentów wynosi: {count}. Lista dokumentów została zaktualizowana.',
  deleteInvoiceSuccess: 'Usunięto wybrany dokument.',
  deleteInvoiceInProgress:
    'Rozpoczęto próbę usuwania dokumentów. Po poprawnym przetworzeniu żądania, faktury znikną z listy.',
  deleteInvoiceFinished: 'Usuwanie faktur',
  deletedMissedInvoicesModalTitle: 'Nie udało się usunąć wszystkich zaznaczonych dokumentów',
  deletedMissedInvoicesModalContent:
    'Liczba faktur, których nie udało się usunąć wynosi {range}. Rozwiń listę poniżej, aby sprawdzić powód.',
  InvoiceNonRemovableReasonNone: 'Dokument został usunięty',
  InvoiceNonRemovableReasonNotOwner: 'Dokument nie jest dodany przez {currentUser}',
  InvoiceNonRemovableReasonWrongType: 'Błędny typ dokumentu',
  InvoiceNonRemovableReasonWrongStatus: 'Dokument został wysłany do KSEF',
  InvoiceNonRemovableReasonAlreadyExported: 'Dokument został wysłany poza Symfonia KSeF',
  InvoiceNonRemovableReasonServerError: 'Wystąpił nieoczekiwany błąd',
  InvoiceNonRemovableReasonNotFound: 'Brak dokumentu w Symfonia KSeF',
  cantDeleteInvoiceTooltip: 'Użytkownik może usunąć tylko dokumenty dodane przez siebie.',
  deleteInvoiceConfirmationModalTitle: 'Czy chcesz usunąć zaznaczony dokument?',
  deleteInvoicesConfirmationModalTitle: 'Czy chcesz usunąć zaznaczone dokumenty?',
  deleteInvoiceConfirmationModalContent: ' ',
  dateFromTo: 'Zakres dat od-do',
  systemErrorMessage: 'Operacja zakończyła się niepowodzeniem. Komunikat otrzymany z systemu: {errorMessage}',
  selected: 'zaznaczone',
  elementsSelected: 'elementy zaznaczone',
  elementsSelectedFromTheList: 'wybrano z listy',
  uncheck: 'Odznacz',
  export: 'Eksportuj',
  exportReportModalTitle: 'Eksport raportu do pliku {fileType}',
  exportReportModalContent: 'Czy chcesz wykonać raport z rekordów znajdujących się na przefiltrowanej liście?',
  exportReportModalContentNoFilter: 'Czy chcesz wykonać raport z rekordów znajdujących się na liście?',
  invoicesDatesTooltip:
    'Jednorazowo możliwe jest pobranie faktur za dowolny okres 2 lat{br}{br} Domyślnie ustawiony zakres dat, to {dateRange} od daty bieżącej.{br}{br} Przed pobraniem upewnij się, czy posiadana przez Ciebie subskrypcja zawiera wystarczającą ilość faktur i miejsca.',
  dateRange90days: '90 dni',
  dateRange2Years: '2 lata',
  systemErrorWithoutInnerMessage: 'Operacja zakończyła się niepowodzeniem.',
  noAcceptedDecree: 'Brak zaakceptowanego dekretu',
  someInvoicesWithoutDecree: 'Niektóre faktury nie posiadają dekretu',
  someInvoicesWithoutDecreeModal:
    '{unavailableNumber} z wybranych faktur nie posiadają dekretu. Po pobraniu faktur do programu Symfonia Finanse i Księgowość nie będzie można wykonać automatycznej dekretacji w programie Symfonia KSeF.',
  noDecree: 'Brak dekretu',
  decreeAccepted: 'Dekret zaakceptowany',
  acceptationForbidden: 'Niektóre faktury nie mogą mieć {br} zaakceptowanego dekretu',
  acceptationForbiddenText:
    '{unavailableNumber} z {selectedNumber} wybranych faktur nie mogą mieć zaakceptowanego dekretu. Rozwiń listę, aby zobaczyć powód.',
  cantAcceptOwnDecree: 'Użytkownik nie może sam akceptować {br} wykonych przez siebie dekretacji.',
  saveAndAcceptDecree: 'Zapisz i zaakceptuj dekretację',
  acceptedSuccess: 'Liczba zaakceptowanych dekretów wynosi {succesMessageValue}.',
  acceptedOneSuccess: 'Zaakceptowano dekret faktury {succesMessageValue}.',
  acceptedFailure: 'Nieudana akceptacja dekretu {decreeNumber}. Spróbuj ponownie.',
  decreesToAccept: 'Dekretacje do akceptacji',
  documentPosted: 'Dokument zadekretowany',
  automaticDecrees: 'Automatyczne dekretacje',
  more: '{prefix}Więcej',
  From: 'Od',
  To: 'Do',
  less: 'Mniej',
  documentMustBeAcceptBySomeoneElse: 'Dekret musi zostać zaakceptowany przez inną osobę',
  tokenExpiredAlert: 'Twój token wygasł. Żeby go odświeżyć, spróbuj ponownie.',
  available: 'Dostępne',
  allNotifications: 'Wszystkie powiadomienia',
  unreadNotifications: 'Nieprzeczytane powiadomienia',
  tokenAuthDesc: 'TOKEN',
  signAuthDesc: 'PODPIS KWALIFIKOWANY',
  authorizedByDesc: 'Wprowadzony przez: {authorizedFullName} {authorizedDate}',
  sharedAccessSwitchLabel: 'Upoważnienie do pobierania faktur z KSeF przez innych pracowników',
  sharedAccessInfo:
    'Włączenie opcji pozwoli na pobieranie faktur z KSeF do zintegrowanych systemów Symfonii przy użyciu Twojego tokena przez innych pracowników. Dotyczy systemów Symfonia Obieg Dokumentów i e-Biuro.',
  sharedAccessActivationInfo: '{activation} przez {user} {dateTime}',
  sharedAccessChangeError:
    'Próba zmiany ustawień upoważnienia do pobierania faktur z KSeF przez innych pracowników zakończyła się niepowodzeniem.',
  onlineDocumentation: 'Dokumentacja online',
  whiteListSwitchLabel: 'Automatyczne sprawdzanie kont bankowych na białej liście',
  whiteListInfo:
    'Włączenie opcji spowoduje, że system podczas pobierania faktur zakupowych z KSeF będzie automatycznie sprawdzał czy konta bankowe umieszczane na tych fakturach znajdują się na białej liście. Informacja ta będzie widoczna z poziomu listy dokumentów.',
  whiteListActivationInfo: '{activation} przez {user} {dateTime}',
  whiteListChangeError:
    'Próba zmiany ustawień sprawdzania kontrahentów na białej liście zakończyła się niepowodzeniem.',
  whiteListCheckAction: 'Sprawdź na białej liście',
  whiteListCheckInProgress: 'Zlecono sprawdzenie kontrahentów na białej liście dla wybranych faktur',
  whiteListCheckRunError: 'Nie udało się zlecić sprawdzenia kontrachentów na białej liście',
  whiteListCheckError: 'Nie udało się zweryfikować kontrahentów na białej liście dla podanych faktur.',
  whiteListCheckPartialError:
    'Nie wszystkie faktury zawierają konta znajdujące się na białej liście Ministerstwa Finansów.',
  whiteListCheckAllAccountsIsEmpty: 'Podane faktury nie zawierają numerów kont. Liczba sprawdzonych faktur: {count}',
  whiteListCheckSuccess:
    'Sukces. Wszystkie konta na wskazanych fakturach znajdują się na białej liście. Liczba sprawdzonych faktur: {count}',
  whiteListCheckSuccessWithNotWhiteListed:
    'Nie wszystkie faktury zawierają konta znajdujące się na białej liście Ministerstwa Finansów. Liczba faktur, których kontrahenci nie znajdują się na białej liście: {notWhiteListedCount} z {count}',
  whiteListValidation: 'Sprawdzenie na białej liście',
  whiteListModalTitle: 'Sprawdzenie na białej liście',
  whiteListModalSubTitle:
    'Nie wszystkie faktury zawierają konta bankowe znajdujące się na białej liście Ministerstwa Finansów',
  whiteListModalShowIssuersButton: 'Lista kontrahentów',
  whiteListModalTableTitle: 'Kontrahenci wraz z numerami kont, których nie ma na białej liście',
  whiteListModalContent: 'Liczba faktur, dla których konta bankowe nie znajdują się na białej liście to {range}',
  issuerWithoutAccounts: 'Brak konta bankowego na fakturze',
  whiteListValidatedIssuerTooltipTitle: '“{issuerName}” znajduje się na białej liście',
  whiteListNotValidatedIssuerTooltipTitle: '“{issuerName}” nie znajduje się na białej liście',
  allIssuerAccountsIsValidatedInWhiteList: 'Konta bankowe wskazane na fakturze znajdują się na białej liście',
  allIssuerAccountsIsNotValidatedInWhiteList: 'Konta bankowe wskazane na fakturze nie znajdują się na białej liście',
  someIssuerAccountsIsValidatedInWhiteList:
    'Nie wszystkie konta bankowe wskazane na fakturze znajdują się na białej liście',
  whiteListAccountsModalContent:
    '{validatedCount} z {allCount} kont bankowych “{issuerName}” znajduje się na białej liście',
  whiteListValidationDate: 'stan na: {dateTime}',
  accountsCheckedInWhiteList: 'Lista sprawdzonych kont bankowych',
  isWhiteListed: 'zgodne',
  isNotWhiteListed: 'niezgodne',
  checkWhiteListInterrupted: 'SPRAWDZANIE FAKTUR POD KĄTEM BIAŁEJ LISTY ZOSTAŁO PRZERWANE',
  checkWhiteListInterruptedContent: 'Liczba faktur, które udało się sprawdzić to {range}',
  showResult: 'Zobacz wyniki sprawdzenia',
  invoicesDateRangeExceedsTwoYearsWarning: 'Zmień wybrany zakres dat - zakres czasu jest dłuższy niż 2 lata',
  personalData: 'Dane osobowe',
  backToPermissions: 'Powrót do Uprawnień',
  SelfInvoicing: 'Samofakturowanie',
  wrongNip: 'Podany NIP jest nieprawidłowy',
  wrongPesel: 'Podany PESEL jest nieprawidłowy',
  typesOfDocuments: 'Typy dokumentów',
  vatRegister: 'Rejestr VAT',
  emailNotifications: 'Notyfikacje e-mail',
  decreeDetails: 'Szczegóły dekretu',
  authorize: 'Uwierzytelnij',
  searchAccount: 'Wyszukaj konto',
  invoicePreviewEnabled: 'Brak faktury do wyświetlenia',
  downloadCSV: 'Pobierz listę (CSV)',
  downloadXLS: 'Pobierz listę (XLS)',
  accountTypes: 'Rodzaj konta',
  goBackToConfiguration: 'Wróć do konfiguracji',
  symfonia: 'Symfonia',
  config: 'Konfiguracja',
  or: 'lub',
  contentOfCategory: 'Zawartość kategorii',
  autopostingConfigTitle: 'Dzień dobry, tu Symfonia!',
  autopostingConfig: 'Skonfiguruj dekretację',
  backToMainMenu: 'Powrót do menu głównego',
  ContractorsModalGoToContractorsList: 'Przejdź do listy kontrahentów',
  contractorAccountsNumbersListAccountHeader: 'Nr konta',
  contractorAccountsNumbersListWhitelistHeader: 'Biała lista',
  contractorAccountsNumbersListDateHeader: 'Sprawdzono',
  contractorAccountsNumbersListNoData: 'Brak danych',
  ContractorTypeSwitchesHeader: 'Typ kontrahenta',
  ContractorTypeSwitchesTradingLabel: 'Handlowy',
  ContractorTypeSwitchesCostLabel: 'Kosztowy',
  ContractorTypeEmptyLabel: 'Brak typu',
  ContractorTypeSwitchesCostAndTradingLabel: 'Kosztowy/Handlowy',
  ContractorTypeSwitchesAllLabel: 'Wszyscy kontrahenci',
  ContractorTypeSavedTradingAndCostAlert: 'Zapisano kontrahenta jako handlowego i kosztowego',
  ContractorTypeSavedTradingAlert: 'Zapisano kontrahenta jako handlowego',
  ContractorTypeSavedCostAlert: 'Zapisano kontrahenta jako kosztowego',
  ContractorMultipleTypeSavedTradingAndCostAlert: 'Zapisano kontrahentów jako handlowegych i kosztowych',
  ContractorMultipleTypeSavedTradingAlert: 'Zapisano kontrahentów jako handlowych',
  ContractorMultipleTypeSavedCostAlert: 'Zapisano kontrahentów jako kosztowych',
  ContractorTypeSavedEmptyAlert: 'Zapisanie danych przebiegło pomyślnie',
  downloadAtTime: 'Godzina pobrania:',
  downloadInterval: 'Pobieraj co:',
  ksefSettings: 'Ustawienia KSeF',
  cancelDownloadInvoices: 'Przerwij pobieranie faktur',
  downloadInvoicesPending: 'Kolejne pobieranie faktur z MF możliwe o godzinie {time}',
  downloadInvoicesProgress: 'Trwa ręczne pobieranie faktur z KSeF. Liczba pobranych faktur: {count}',
  autoFetchingInvoicesProgress: 'Trwa automatyczne pobieranie faktur z KSeF. Liczba pobranych faktur: {count}',
  invoicesDownloadRunning:
    'W chwili obecnej trwa już proces pobierania faktur z Krajowego Systemu e-Faktur dla bieżącej firmy. Będzie można rozpocząć nowe pobieranie gdy obecny proces dobiegnie końca.',
  downloadLimitDate: 'Limit daty pobierania faktur',
  downloadLimitDateTooltip_Content: 'Limit daty ograniczający zakres automatycznie pobieranych faktur.',
  downloadLimitDateWarning:
    'Jeśli data nie zostanie wybrana, zostaną pobrane faktury z okresu 2 lat wstecz od daty bieżącej, co może skutkować szybszym zużyciem pakietu.',
  attachments: 'Załączniki',
  attachmentsList: 'Lista załączników',
  attached: 'Załączono',
  addAttachments: 'Dodaj załącznik',
  searchInTheList: 'Wyszukaj na liście',
  dateOfAdding: 'Data dodania',
  addedBy: 'Dodał',
  size: 'Wielkość',
  privateStatus: 'Prywatny',
  publicStatus: 'Publiczny',
  addingAttachment: 'Dodawanie załącznika',
  formatOfFile: 'Nieobsługiwany format pliku',
  cantAddFiles: 'Nie można dodać plików: <b> {files} </b> {br} Pliki z rozszerzeniem <b> {format} </b> nie są obsługiwane',
  cantAddFile: 'Nie można dodać pliku: <b> {files} </b> {br} Pliki z rozszerzeniem <b> {format} </b> nie są obsługiwane',
  notSupportedFiles: 'Pliki z rozszerzeniem {format} nie są obsługiwane',
  changeToPublic: 'Zmień na publiczny',
  changeToPrivate: 'Zmień na prywatny',
  deletingAttachments: 'Usuwanie załączników',
  confirmDeletingAttachment: 'Czy na pewno chcesz trwale usunąć załącznik:',
  confirmDeletingAttachments: 'Czy napewno chcesz trwale usunąć załączniki:',
  attachmentDeletedSuccess: 'Usunięto załącznik: {fileName}',
  attachmentsDeletedSuccess: 'Usunięto załączniki',
  attachmentDeletedError: 'Nie udało się usunąć załącznika: {fileName}',
  attachmentsDeletedError: 'Nie udało się usunąć załączników',
  attachmentAddedSuccess: 'Udało się zapisać załącznik',
  attachmentAddedError: 'Nie udało się zapisać załącznika',
  undo: 'Cofnij',
  downloadAttachments: 'Pobierz załączniki',
  invoiceFormat: 'Format faktury',
  formatPDF: 'PDF',
  formatXML: 'XML',
  formatPDFandXML: 'PDF i XML',
  noInvoice: 'Brak faktury',
  formatInvoiceTooltipContent:
    'Użytkownik ma możliwość dołączenia oryginalnej faktury źródłowej do folderu o wybranym typie rozszerzenia. Wszystkie inne załączniki zostaną zachowane w formatach które były wcześniej dodane.',
  contractors: 'Kontrahenci',
  contractorsList: 'Lista kontrahentów',
  accountNumber: 'Nr konta',
  taxPayerStatus: 'Status podatnika',
  accountStatus: 'Status konta bankowego',
  bankAccounts: 'Konta bankowe',
  singleBankAccount: 'Konto bankowe',
  noAccounts: 'Brak kont',
  switchContractorType: 'Zmień typ kontrahenta',
  selectedContractorsCount: 'Liczba zaznaczonych kontrahentów',
  changeSelectedContractorsType: 'Zmień typ wybranym kontrahentom',
  filehubMultipleActionsRequestSuccess: 'Pomyślnie zapisano zmiany załączników',
  filehubMultipleActionsRequestFail: 'Nie udało się zapisać zmiany załączników',
  filehubNoAttachments: 'Brak dodanych załączników',
  filehubDownloadedAttachmentSuccessTitle: 'Pobrano załącznik',
  filehubDownloadedAttachmentFailedTitle: 'Nie udało się pobrać załączników',
  filehubDownloadedAttachmentFailedDescriptionUnknown:
    'Faktura zakupu, {fileName} - z niezależnej przyczyny wystąpił błąd pobierania pliku',
  filehubDownloadedAttachmentFailedDescriptionMaxSize:
    'Faktura zakupu, {fileName} - waga pobieranych plików przekroczyły pojemność folderu 50MB',
    filehubDownloadedAttachmentsSuccessTitle: "Pobrano załączniki",
    filehubDownloadAttachmentSizeExceededTitle: "Waga pobieranych plików: {size} przekroczyła dopuszczalny limit 200MB",
    filehubDownloadAttachmentSizeExceeded: "Przekroczono limit",
    sendingInfo: "Wysyłanie wiadomości",
    sendByEmail: "Wyślij wiadomością e-mail",
    purchaseInvoiceFormat: "Format faktury zakupu, ",
    attachingPDFfileInfo: "Załączenie pliku faktury zwiększa zużycie dostępnego miejsca.",
    publicAttachments: "Publiczne załączniki: ",
    gotoAttachmentsList: "Przejdź do listy załączników",
    messageText: "Treść wiadomości",
    emailMessage: "Pragniemy poinformować, że firma {companyName} z programu Symfonia przesłała do Ciebie link z załącznikami do faktury o numerze {invoiceNumber}.",
    editEmailMessage: "Edytuj treść wiadomości",
    messageInfoText: "Wygenerowana wiadomość e-mail zawiera wszystkie załączniki o statusie publicznym",
    attachmentsAddedSuccess: "Udało się dodać załączniki",
    attachmentsAddedError: "Nie udało się dodać załączników",
    filehubStatusTooltipText: "Użytkownik ma możliwość zarządzania widocznością plików udostępnionych w przestrzeni dyskowej. {br} Status Publiczny - plik dostępny w przestrzeni dyskowej. {br} Status Prywatny - plik niedostępny w przestrzeni dyskowej.",
    requiredEmailField: "Pole obowiązkowe - przykład jan.kowalski@domena.pl",
    requiredEmailFieldUserName : "Nazwa użytkownika (np. jan.kowalski)",
    requiredEmailFieldMonkeySign : "Znak @ (dokładnie jeden)",
    requiredEmailFieldDotSign : "Znak kropki przynajmniej jeden przed rozszerzeniem",
    requiredEmailFieldDomain : "Domena (np. domena.pl)",
    requiredEmailFieldMainDomain : "Rozszerzenie (np. .com .pl)",
    emailAdres : "Adres e-mail",
    setEmailAdres : "Podaj adres e-mail",
    loading: "Ładowanie",
    filehubMessageSentToReceiver: "Wiadomość została wysłana do odbiorcy",
    filehubMessageNotSentToReceiver: "Nie udało wysłać się wiadomości do odbiorcy",
    cantAddFolder: 'Nie można dodać folderu plików {files}',
    uploadFoldersProhibited: 'Foldery plików nie są obsługiwane',
    send: "Wyślij",
    filehubUploadFilesWithInvalidCharacters:'Nieobsługiwana nazwa pliku',
    filehubCantAddFileWithInvalidCharacters: 'Nie można dodać pliku: {files} {br}Pliki ze znakami specjalnymi: {br}{invalidCharacters} {br}nie są obsługiwane',
    filehubUploadFilesWithFileSizeExceeded:'Przekroczony rozmiar pliku',
    filehubCantAddFileWithFileSizeExceeded: 'Nie można dodać pliku: <b> {files} </b> {br}Rozmiar pliku przekracza 100MB',
    saveEmailMessage: "Zapisz treść wiadomości",
    filehubGetAttachmentsErrorTitle: "Nie udało się pobrać załączników",
    filehubAbandonChanges: "Czy na pewno chcesz porzucić zmiany?",
    filehubSizeLimitHeader: "Osiągnięto limit miejsca",
    filehubSizeLimitContentSingle: "Nie można dodać pliku: <b> {fileName}</b>. Przekroczono dostępne miejsce na serwerze. Dokup pakiet lub usuń inne pliki. {br} {br} <link>Zobacz ofertę pakietów</link>",
    filehubSizeLimitContentMultiple: "Nie można dodać plików: <b> {fileName}</b>. Przekroczono dostępne miejsce na serwerze. Dokup pakiet lub usuń inne pliki. {br} {br} <link>Zobacz ofertę pakietów</link>",
    filehubUndoConfirmation: "Czy na pewno chcesz cofnąć zmiany?",
    calendarModeDay: "Dzień - Miesiąc - Rok",
    calendarModeMonth: "Miesiąc - Rok",
    calendarModeYear: "Rok",
    chooseFile: "Wybierz plik",
    dragAndDropHere: "Przeciągnij i upuść tutaj",
    shares: "Udostępnienia",
    selectAll: "Zaznacz wszystkie",
    sharesFilesSize: "Rozmiar plików",
    sharesShareStartDate: "Data udostępnienia",
    sharesShareStatus: "Status udostępnienia",
    link: "Link",
    pinProtection: "Zabezpieczone PIN-em",
    shareLinkTooltipText : "Każda osoba dysponująca linkiem może pobierać zawartość",
    disable: "Dezaktywuj",
    enable: "Aktywuj",
    share: "Udostępnij",
    copyLink: "Kopiuj link",
    sharePublicAtachments: "Publiczne załączniki",
    copiedToClipboard: "Skopiowano do schowka",
};
