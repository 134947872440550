import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {Tabs} from '@symfonia/brandbook';
import {useLocation} from 'react-router-dom';
import {isEarchive, isLoginHub} from '../common/helpers/checkCurrentPath';
import {EnvironmentType} from '@symfonia-ksef/graphql';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {UserMenu} from './components/UserMenu/UserMenu';
import Logo from '../../assets/Logo.svg';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {earchiveStateMobx} from '../earchive';
import {ModulesEnum} from '../common/enums/ModulesEnum';
import {observer} from 'mobx-react-lite';
import {UserMenuMobile} from './components/UserMenu/UserMenuMobile';
import {isFeatureEnabled} from '../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../common/enums/FeatureSwitchTypeEnum';

export interface HeaderProps extends PropsWithChildren {
  title: string;
}

export const Header: FC<HeaderProps> = observer(({title, children}) => {

  const [activeTab, setActiveTab] = useState<number>(0);
  const {tenantId, companyId, currentEnvironment} = earchiveState.company;
  const documentsHref = `/earchive/${tenantId}/company/${companyId}`;

  const {pathname} = useLocation();

  useEffect(() => {
    if (!pathname.includes('shares')) {
      pathname.includes('autoposting') ? setActiveTab(1) : setActiveTab(0);
    } else {
      setActiveTab(2);
    }
  }, [pathname, earchiveState.company.companyId]);

  const {environments} = earchiveState.environments;
  const isTenant = !!environments.find(el => el.Type === EnvironmentType.Tenant && tenantId === el?.Tenant?.Id);
  const isAdmin = isEarchive() && !isLoginHub() && isTenant;

  const {isPhone, isTablet, isSmallPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);

  const isAutopostingOn = earchiveStateMobx.moduleStatusStore.modules.find(x => x.moduleType === ModulesEnum.autoPosting)?.isModulePurchased;
  const isFilehubOn = isFeatureEnabled(FeatureSwitchTypeEnum.filehub);

  const appBarTabs = [
    {
      text: 'KSeF',
      href: `${documentsHref}/documents/purchase`,
      isActive: activeTab === 0 && (pathname.includes('documents/purchase') || pathname.includes('documents/sales')),
      onClick: () => setActiveTab(0),
    },
    {
      text: 'Dekretacje',
      href: `${documentsHref}/autoposting`,
      isActive: activeTab === 1,
      onClick: () => setActiveTab(1),
      className: isAutopostingOn ? '' : ' hidden',
    },
    {
      text: "Udostępnienia",
      href: `${documentsHref}/shares`,
      isActive: activeTab === 2 && pathname.includes('shares'),
      onClick: () => setActiveTab(2),
      className: isFilehubOn ? '' : ' hidden',
    }
  ];

  return (
    <div className={'sticky bg-white w-full box-border z-50 top-0 right-0 shadow-md border-gray-200 border-b'}>
      <div className={'relative bg-white w-full flex justify-between items-center' +
        ' h-[64px] mx-auto max-w-[1920px] ' + (isPhone ? 'px-[15px]' : 'px-[28px]')}>

        <section className={'flex items-start justify-center h-full'}>
          <div className={'flex items-center justify-center my-auto ' + (isPhone ? 'mr-0' : 'mr-[40px]')}>
            <img src={Logo} alt="logo"/>
          </div>
          {!isPhone && !isTablet && currentEnvironment && isEarchive() &&
            <Tabs
              tabs={appBarTabs}
              className={'h-full items-end [&>span>a]:h-full [&>span]:h-full relative z-50 '}
              isActive={tab => tab.isActive ?? false}
            />
          }
        </section>

        <section className={'flex items-end items-center'}>
          {!isPhone && !isSmallPhone
            ? <UserMenu tabs={appBarTabs} isAdmin={isAdmin}/>
            : <UserMenuMobile tabs={appBarTabs} isAdmin={isAdmin}/>
          }
        </section>
      </div>
    </div>
  );
});
